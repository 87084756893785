import { AlertProps } from '@mui/material';
import { createContext, useContext } from 'react';

export type Alert = {
  message: string;
  severity?: AlertProps['severity'];
};

export const SnackbarAlertContext = createContext<
  | {
      alert?: Alert;
      setAlert: (alert: Alert) => void;
      close: () => void;
    }
  | Record<string, never>
>({});

export function useSnackbarAlertContext() {
  const context = useContext(SnackbarAlertContext);

  if (context === undefined) {
    throw new Error(
      'useSnackbarAlertContext must be used within a SnackbarAlertContext.Provider',
    );
  }

  return context;
}
