import { createTheme } from '@mui/material';
import type {} from '@mui/x-date-pickers/themeAugmentation';
// eslint-disable-next-line no-restricted-imports
import type {} from '@mui/lab/themeAugmentation';
import { palette } from './palette';
import { components } from './themeComponents';

export const theme = createTheme({
  spacing: 4,
  palette,
  components,
});
