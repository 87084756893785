import { Alert, Box, Button, Typography } from '@mui/material';
import { LoadingButton } from '@mui/lab';
import { StyledModalFooter } from '@/components/ui/styles/StyledModal';
import { DrawerError } from '@/components/ui/drawers/DrawerError';

type MarkAsCompleteModalContentProps = {
  isError: boolean;
  isPending: boolean;
  onConfirm: () => void;
  onCancel: () => void;
};

export function MarkAsCompleteModalContent({
  isError,
  isPending,
  onConfirm,
  onCancel,
}: MarkAsCompleteModalContentProps) {
  return (
    <Box p={6}>
      {isError && <DrawerError />}
      <Alert data-testid="reminders_mark_as_complete_alert" severity="warning">
        You are about to mark this reminder as complete
      </Alert>
      <Typography variant="body2" my={6}>
        Please confirm all tasks for this reminder have been completed.
      </Typography>
      <StyledModalFooter>
        <LoadingButton
          data-testid="reminders_confirm-mark-as-complete-button"
          variant="contained"
          color="secondary"
          loading={isPending}
          onClick={onConfirm}
        >
          Confirm and mark as complete
        </LoadingButton>
        <Button
          data-testid="reminders_cancel-mark-as-complete-button"
          variant="outlined"
          color="inherit"
          onClick={onCancel}
        >
          Go back
        </Button>
      </StyledModalFooter>
    </Box>
  );
}
