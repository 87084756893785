import { NavLink } from 'react-router-dom';
import SearchOutlinedIcon from '@mui/icons-material/SearchOutlined';
import NotificationsIcon from '@mui/icons-material/Notifications';
import GroupIcon from '@mui/icons-material/Group';
import { StyledAppBar, StyledToolbar } from './SideNav.styles';
import { SideNavLink } from './components/SideNavLink';
import { DayinsureLogo } from '@/components/ui/presentation/DayInsureLogo';
import { RoutePath } from '@/models/RoutePath';
import { useUser } from '@/hooks/useUser';

export function SideNav() {
  const { hasPermissions } = useUser();

  return (
    <StyledAppBar>
      <NavLink style={{ color: '#fff' }} to={RoutePath.Root}>
        <DayinsureLogo />
      </NavLink>
      <StyledToolbar>
        <SideNavLink title="Search" to={RoutePath.Search}>
          <SearchOutlinedIcon />
        </SideNavLink>
        {hasPermissions(['admin:reminders']) && (
          <SideNavLink title="Reminders" to={RoutePath.AdminReminders}>
            <NotificationsIcon />
          </SideNavLink>
        )}
        {hasPermissions(['manageusers:support']) && (
          <SideNavLink title="User management" to={RoutePath.Users}>
            <GroupIcon />
          </SideNavLink>
        )}
      </StyledToolbar>
    </StyledAppBar>
  );
}
