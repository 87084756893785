import { StyledAppBar, StyledToolBar } from './Navbar.styles';
import { IndividualReminders } from '@/features/IndividualReminders';
import { Account } from '@/features/Navigation/Navbar/components/Account';
import { useUser } from '@/hooks/useUser';

export function Navbar() {
  const { hasPermissions } = useUser();

  return (
    <StyledAppBar position="fixed">
      <StyledToolBar id="navbar-content" />
      {hasPermissions(['readany:reminders']) && <IndividualReminders />}
      <Account />
    </StyledAppBar>
  );
}
