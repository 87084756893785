/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { AbiVehicleService } from './services/AbiVehicleService';
import { AcceptanceCriteriaService } from './services/AcceptanceCriteriaService';
import { AddressService } from './services/AddressService';
import { AssetService } from './services/AssetService';
import { MotorPolicyService } from './services/MotorPolicyService';
import { MotorQuoteService } from './services/MotorQuoteService';
import { OccupationService } from './services/OccupationService';
import { OrderService } from './services/OrderService';
import { PaymentMethodService } from './services/PaymentMethodService';
import { PaymentSessionService } from './services/PaymentSessionService';
import { PcwDeeplinkService } from './services/PcwDeeplinkService';
import { PersonService } from './services/PersonService';
import { PolicyService } from './services/PolicyService';
import { ProductService } from './services/ProductService';
import { QuoteService } from './services/QuoteService';
import { ValidatePersonService } from './services/ValidatePersonService';
import { VehicleService } from './services/VehicleService';
import { VehicleTypeService } from './services/VehicleTypeService';
import { WebhookService } from './services/WebhookService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class RetailAPI {
  public readonly abiVehicle: AbiVehicleService;
  public readonly acceptanceCriteria: AcceptanceCriteriaService;
  public readonly address: AddressService;
  public readonly asset: AssetService;
  public readonly motorPolicy: MotorPolicyService;
  public readonly motorQuote: MotorQuoteService;
  public readonly occupation: OccupationService;
  public readonly order: OrderService;
  public readonly paymentMethod: PaymentMethodService;
  public readonly paymentSession: PaymentSessionService;
  public readonly pcwDeeplink: PcwDeeplinkService;
  public readonly person: PersonService;
  public readonly policy: PolicyService;
  public readonly product: ProductService;
  public readonly quote: QuoteService;
  public readonly validatePerson: ValidatePersonService;
  public readonly vehicle: VehicleService;
  public readonly vehicleType: VehicleTypeService;
  public readonly webhook: WebhookService;
  public readonly request: BaseHttpRequest;
  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });
    this.abiVehicle = new AbiVehicleService(this.request);
    this.acceptanceCriteria = new AcceptanceCriteriaService(this.request);
    this.address = new AddressService(this.request);
    this.asset = new AssetService(this.request);
    this.motorPolicy = new MotorPolicyService(this.request);
    this.motorQuote = new MotorQuoteService(this.request);
    this.occupation = new OccupationService(this.request);
    this.order = new OrderService(this.request);
    this.paymentMethod = new PaymentMethodService(this.request);
    this.paymentSession = new PaymentSessionService(this.request);
    this.pcwDeeplink = new PcwDeeplinkService(this.request);
    this.person = new PersonService(this.request);
    this.policy = new PolicyService(this.request);
    this.product = new ProductService(this.request);
    this.quote = new QuoteService(this.request);
    this.validatePerson = new ValidatePersonService(this.request);
    this.vehicle = new VehicleService(this.request);
    this.vehicleType = new VehicleTypeService(this.request);
    this.webhook = new WebhookService(this.request);
  }
}

