/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddCustomerVulnerabilityRequestDto } from '../models/AddCustomerVulnerabilityRequestDto';
import type { AddCustomerVulnerabilityResponseDto } from '../models/AddCustomerVulnerabilityResponseDto';
import type { GetCustomerVulnerabilitiesResponseDto } from '../models/GetCustomerVulnerabilitiesResponseDto';
import type { UpdateCustomerVulnerabilityRequestDto } from '../models/UpdateCustomerVulnerabilityRequestDto';
import type { UpdateCustomerVulnerabilityResponseDto } from '../models/UpdateCustomerVulnerabilityResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CustomerVulnerabilitiesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns AddCustomerVulnerabilityResponseDto Success
   * @throws ApiError
   */
  public postApiV1CustomerVulnerabilities({
    requestBody,
  }: {
    requestBody?: AddCustomerVulnerabilityRequestDto,
  }): CancelablePromise<AddCustomerVulnerabilityResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/customer-vulnerabilities',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns GetCustomerVulnerabilitiesResponseDto Success
   * @throws ApiError
   */
  public getApiV1CustomerVulnerabilities({
    policyId,
  }: {
    policyId?: string,
  }): CancelablePromise<GetCustomerVulnerabilitiesResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/customer-vulnerabilities',
      query: {
        'policyId': policyId,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public deleteApiV1CustomerVulnerabilities({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/customer-vulnerabilities/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @returns UpdateCustomerVulnerabilityResponseDto Success
   * @throws ApiError
   */
  public patchApiV1CustomerVulnerabilities({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: UpdateCustomerVulnerabilityRequestDto,
  }): CancelablePromise<UpdateCustomerVulnerabilityResponseDto> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/customer-vulnerabilities/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }
}
