/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_CreateMtaRequestDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_CreateMtaRequestDto';
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_CreateSupportMtaRequestDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_CreateSupportMtaRequestDto';
import type { Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_CreateMtaResponseDto } from '../models/Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_CreateMtaResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MtaService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_CreateMtaResponseDto Success
   * @throws ApiError
   */
  public postApiV1Mta({
    requestBody,
  }: {
    requestBody?: Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_CreateMtaRequestDto,
  }): CancelablePromise<Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_CreateMtaResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/Mta',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
  /**
   * @returns Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_CreateMtaResponseDto Success
   * @throws ApiError
   */
  public postApiV1MtaSupportMta({
    requestBody,
  }: {
    requestBody?: Dayinsure_Common_Contracts_MotorPolicy_Dtos_Request_CreateSupportMtaRequestDto,
  }): CancelablePromise<Dayinsure_Common_Contracts_MotorPolicy_Dtos_Response_CreateMtaResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/Mta/support-mta',
      body: requestBody,
      mediaType: 'application/json',
    });
  }
}
