import { Tabs, Tab } from '@mui/material';
import { ReminderFilterType } from '@/api/note';

type IndividualRemindersTabsProps = {
  onTabChange: (filterType: ReminderFilterType) => void;
  filterParam: ReminderFilterType;
};

export function IndividualRemindersTabs({
  onTabChange,
  filterParam,
}: IndividualRemindersTabsProps) {
  return (
    <Tabs
      value={filterParam}
      onChange={(_event, value: ReminderFilterType) => onTabChange(value)}
    >
      <Tab
        value={ReminderFilterType.INCOMPLETE}
        label="Existing"
        data-testid="individual-reminders_existing-tab"
      />
      <Tab
        value={ReminderFilterType.COMPLETE}
        label="Previous"
        data-testid="individual-reminders_previous-tab"
      />
    </Tabs>
  );
}
