import { useState } from 'react';
import { IndividualRemindersContent } from './IndividualRemindersContent';
import { IndividualRemindersTabs } from './IndividualRemindersTabs';
import { StyledReminderDetailWrapper } from './IndividualRemindersContent/IndividualRemindersContent.styles';
import { ReminderFilterType } from '@/api/note';
import { DrawerContent } from '@/components/ui/drawers/DrawerContent';
import { DrawerHeader } from '@/components/ui/drawers/DrawerHeader';

export function IndividualRemindersDrawerContent() {
  const [filter, setFilter] = useState(ReminderFilterType.INCOMPLETE);

  const onTabChange = (filterType: ReminderFilterType) => setFilter(filterType);

  return (
    <DrawerContent>
      <DrawerHeader title="Reminders">
        <IndividualRemindersTabs filterParam={filter} onTabChange={onTabChange} />
      </DrawerHeader>
      <StyledReminderDetailWrapper>
        <IndividualRemindersContent filterParam={filter} />
      </StyledReminderDetailWrapper>
    </DrawerContent>
  );
}
