/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AdyenNotificationRequest } from '../models/AdyenNotificationRequest';
import type { AdyenNotificationResponseIAPIResult } from '../models/AdyenNotificationResponseIAPIResult';
import type { IAPIResult } from '../models/IAPIResult';
import type { JumioCallbackNotificationRequest } from '../models/JumioCallbackNotificationRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class WebhookService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns AdyenNotificationResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1WebhookAdyenNotification({
    requestBody,
  }: {
    requestBody?: AdyenNotificationRequest,
  }): CancelablePromise<AdyenNotificationResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/webhook/adyen-notification',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1WebhookJumioCallbackNotification({
    requestBody,
  }: {
    requestBody?: JumioCallbackNotificationRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/webhook/jumio-callback-notification',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
