import { AppBar, Toolbar, styled } from '@mui/material';
import { UiSpacing } from '@/models/UiSpacing';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  background: theme.palette.common.white,
  boxShadow: theme.shadows['1'],
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  paddingRight: theme.spacing(8),
  zIndex: theme.zIndex.drawer + 1,
  height: theme.spacing(UiSpacing.NavbarHeight),
  width: `calc(100% - ${theme.spacing(UiSpacing.SidebarWidth)})`,

  [theme.breakpoints.down('lg')]: {
    paddingRight: theme.spacing(0),
  },
}));

export const StyledToolBar = styled(Toolbar)(({ theme }) => ({
  display: 'flex',
  flexGrow: 1,

  [theme.breakpoints.down('lg')]: {
    padding: 0,
    width: 'inherit',
  },
}));
