/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetListIAPIResult } from '../models/AssetListIAPIResult';
import type { ConfirmEmailChangeRequest } from '../models/ConfirmEmailChangeRequest';
import type { ConfirmPasswordResetRequest } from '../models/ConfirmPasswordResetRequest';
import type { ConfirmPhoneNumberChangeRequest } from '../models/ConfirmPhoneNumberChangeRequest';
import type { ConvertToCustomerRequest } from '../models/ConvertToCustomerRequest';
import type { CreatePersonRequest } from '../models/CreatePersonRequest';
import type { EmailChangeRequest } from '../models/EmailChangeRequest';
import type { IAPIResult } from '../models/IAPIResult';
import type { IdCheckDataResponseListIAPIResult } from '../models/IdCheckDataResponseListIAPIResult';
import type { IdCheckStatus } from '../models/IdCheckStatus';
import type { IdCheckType } from '../models/IdCheckType';
import type { IdentityCheckReponseIAPIResult } from '../models/IdentityCheckReponseIAPIResult';
import type { IdentityCheckRequest } from '../models/IdentityCheckRequest';
import type { LicenceIAPIResult } from '../models/LicenceIAPIResult';
import type { MarketingPreferenceIEnumerableIAPIResult } from '../models/MarketingPreferenceIEnumerableIAPIResult';
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import type { PersonIAPIResult } from '../models/PersonIAPIResult';
import type { PhoneNumberChangeRequest } from '../models/PhoneNumberChangeRequest';
import type { PolicySummaryListIAPIResult } from '../models/PolicySummaryListIAPIResult';
import type { QuoteSummaryListIAPIResult } from '../models/QuoteSummaryListIAPIResult';
import type { SaveMarketingPreferencesRequest } from '../models/SaveMarketingPreferencesRequest';
import type { SendPasswordResetRequest } from '../models/SendPasswordResetRequest';
import type { SendVerificationEmailRequest } from '../models/SendVerificationEmailRequest';
import type { UpdatePersonRequest } from '../models/UpdatePersonRequest';
import type { VerifyEmailAddressRequest } from '../models/VerifyEmailAddressRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PersonService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Gets a person given an ID
   * @returns PersonIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Person({
    id,
  }: {
    /**
     * An ID representing a person
     */
    id: string,
  }): CancelablePromise<PersonIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Updates an existing person given the id.  Properties given will be added or updated
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV1Person({
    id,
    requestBody,
  }: {
    /**
     * An ID representing a person
     */
    id: string,
    /**
     * Request containing a persons details.
     */
    requestBody?: UpdatePersonRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Deletes an existing person given the id.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public deleteApiV1Person({
    id,
  }: {
    /**
     * An ID representing a person
     */
    id: string,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/person/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get policy summaries by the user id.
   * @returns PolicySummaryListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonPolicySummary({
    personId,
  }: {
    /**
     * An ID representing a person Id
     */
    personId: string,
  }): CancelablePromise<PolicySummaryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{personId}/policy-summary',
      path: {
        'personId': personId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get quote summaries by the user id.
   * @returns QuoteSummaryListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonQuoteSummary({
    personId,
    take,
  }: {
    /**
     * An ID representing a person Id
     */
    personId: string,
    /**
     * An int value representing the number of quote summaries the user would like to see
     */
    take?: number,
  }): CancelablePromise<QuoteSummaryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{personId}/quote-summary',
      path: {
        'personId': personId,
      },
      query: {
        'take': take,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Creates a new person object given properties of that person
   * @returns PersonIAPIResult Success
   * @throws ApiError
   */
  public postApiV1Person({
    requestBody,
  }: {
    /**
     * Request containing a persons details.
     */
    requestBody?: CreatePersonRequest,
  }): CancelablePromise<PersonIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
      },
    });
  }
  /**
   * Sends a new email verification email to the given email address if an unverified person with the email address exists
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonSendEmailVerification({
    email,
    requestBody,
  }: {
    /**
     * The email address to send the verification to
     */
    email: string,
    /**
     * Model containing the return Url
     */
    requestBody?: SendVerificationEmailRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{email}/send-email-verification',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * Verifies the email address of the user by security id.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonVerifyEmailAddress({
    personId,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
    /**
     * Model containing the email details to be verified
     */
    requestBody?: VerifyEmailAddressRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/verify-email-address',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Not Found`,
      },
    });
  }
  /**
   * Checks email details and dispatches a verification email.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonRequestEmailChange({
    personId,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
    /**
     * Model containing the new email details requested
     */
    requestBody?: EmailChangeRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/request-email-change',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Uses the email token to confirm the email change to an account and update username
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonConfirmEmailChange({
    personId,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
    /**
     * Model containing the new email details to be confirmed and updated
     */
    requestBody?: ConfirmEmailChangeRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/confirm-email-change',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Sends a password reset email.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonSendPasswordReset({
    email,
    requestBody,
  }: {
    /**
     * The email address to send the password reset email to.
     */
    email: string,
    /**
     * Model containing the return Url.
     */
    requestBody?: SendPasswordResetRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{email}/send-password-reset',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
      },
    });
  }
  /**
   * Uses the password token to confirm request and updates the email
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonConfirmPasswordReset({
    personId,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
    /**
     * Model containing password and password token
     */
    requestBody?: ConfirmPasswordResetRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/confirm-password-reset',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Updates the current password with the new password provided
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV1PersonChangePassword({
    personId,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
    /**
     * Model containing the current and new password
     */
    requestBody?: PasswordChangeRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{personId}/change-password',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Checks phone number details and dispatches a verification code.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonRequestPhoneNumberChange({
    personId,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
    /**
     * Model containing the new phone number details requested
     */
    requestBody?: PhoneNumberChangeRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/request-phone-number-change',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Uses the phone token to confirm the phone number change to an account
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonConfirmPhoneNumberChange({
    personId,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
    /**
     * Model containing the new phone number details to be confirmed and updated
     */
    requestBody?: ConfirmPhoneNumberChangeRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{personId}/confirm-phone-number-change',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets all assets belonging to a person
   * @returns AssetListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonAsset({
    personId,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
  }): CancelablePromise<AssetListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{personId}/asset',
      path: {
        'personId': personId,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Save marketing preferences for a person
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV1PersonMarketingPreferences({
    personId,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
    /**
     * Request containg the marketing data to be saved for the user
     */
    requestBody?: SaveMarketingPreferencesRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{personId}/marketing-preferences',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
      },
    });
  }
  /**
   * Gets all marketing preferences belonging to a person
   * @returns MarketingPreferenceIEnumerableIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonMarketingPreferences({
    personId,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
  }): CancelablePromise<MarketingPreferenceIEnumerableIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{personId}/marketing-preferences',
      path: {
        'personId': personId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets a licence belonging to a person.
   * @returns LicenceIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonLicence({
    id,
  }: {
    /**
     * The security id representing the person identification
     */
    id: string,
  }): CancelablePromise<LicenceIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/licence',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Create/Update an account for end user
   * @returns IdentityCheckReponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1PersonIdCheck({
    id,
    requestBody,
  }: {
    /**
     * An ID representing a security id
     */
    id: string,
    /**
     * Model containing request details
     */
    requestBody?: IdentityCheckRequest,
  }): CancelablePromise<IdentityCheckReponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/person/{id}/id-check',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get the ID Checks for a person.
   * @returns IdCheckDataResponseListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PersonIdCheck({
    id,
    type,
    drivingLicenceNumber,
    groupId,
    status,
  }: {
    /**
     * An ID representing a security id
     */
    id: string,
    /**
     * The Type of ID Check
     */
    type?: IdCheckType,
    /**
     * The Driving Licence Number of the person
     */
    drivingLicenceNumber?: string,
    groupId?: number,
    status?: IdCheckStatus,
  }): CancelablePromise<IdCheckDataResponseListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/person/{id}/id-check',
      path: {
        'id': id,
      },
      query: {
        'type': type,
        'drivingLicenceNumber': drivingLicenceNumber,
        'groupId': groupId,
        'status': status,
      },
      errors: {
        404: `Not Found`,
      },
    });
  }
  /**
   * convert guest into DI account holder
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV1PersonConvertToCustomer({
    id,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    id: string,
    /**
     * Model containing the current and new password
     */
    requestBody?: ConvertToCustomerRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/person/{id}/convert-to-customer',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
}
