import { Box, Badge, styled } from '@mui/material';
import NotificationsNoneOutlinedIcon from '@mui/icons-material/NotificationsNoneOutlined';

export const StyledNotificationsIcon = styled(NotificationsNoneOutlinedIcon)(
  ({ theme }) => ({
    width: '24px',
    height: '24px',
    cursor: 'pointer',
    color: theme.palette.grey[400],
  }),
);

export const StyledBox = styled(Box)(() => ({
  position: 'relative',
  alignItems: 'center',
  display: 'flex',
}));

export const StyledBadge = styled(Badge)(({ theme }) => ({
  position: 'absolute',
  right: 0,
  top: 0,
  marginTop: theme.spacing(1.5),
  marginRight: theme.spacing(1.5),
  zIndex: 2,
  backgroundColor: theme.palette.error.main,
  '& .MuiBadge-dot': {
    backgroundColor: theme.palette.error.main,
  },
}));
