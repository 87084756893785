import { Divider, Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { StyledDrawerHeader, StyledDrawerTitle } from './Drawer.styles';
import { CloseIconButton } from '@/components/ui/buttons/CloseIconButton';
import { useDrawerContext } from '@/hooks/contexts/useDrawerContext';

type DrawerHeaderProps = PropsWithChildren<{ title: string }>;

export function DrawerHeader({ title, children }: DrawerHeaderProps) {
  const { close } = useDrawerContext();

  return (
    <StyledDrawerHeader>
      <Stack p={6} justifyContent="space-between" alignItems="center">
        <StyledDrawerTitle>{title}</StyledDrawerTitle>
        <CloseIconButton testId="ui_drawer-close-button" onClose={close} />
      </Stack>
      {children}
      <Divider />
    </StyledDrawerHeader>
  );
}
