/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum FilterType {
  PRODUCT_OR_CUSTOMER = 'ProductOrCustomer',
  VEHICLE_REGISTRATION = 'VehicleRegistration',
  POSTCODE = 'Postcode',
  EMAIL = 'Email',
}
