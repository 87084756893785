import { z } from 'zod';
import { Permission, PermissionSchema } from '@/models/Permission';
import { useAuthTokenPayload } from '@/hooks/useAuthTokenPayload';

const PermissionsSchema = z
  .array(z.union([PermissionSchema, z.string()]))
  .transform(permissions =>
    permissions.filter(
      (permission): permission is Permission =>
        PermissionSchema.safeParse(permission).success,
    ),
  );

const getUserPermissions = (textPermissions: string | undefined) => {
  if (!textPermissions) return [];
  return PermissionsSchema.parse(textPermissions.split(' '));
};

export function useUser() {
  const payload = useAuthTokenPayload();
  const userPermissions = getUserPermissions(payload?.permissions);

  const isUserId = (id: string): boolean =>
    payload?.personId != null && payload.personId === id;

  const hasPermissions = (permissions: Permission[]): boolean => {
    return permissions.every(permissions => userPermissions.includes(permissions));
  };

  return {
    userPermissions,
    isUserId,
    hasPermissions,
  };
}
