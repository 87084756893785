import { Outlet } from 'react-router-dom';
import { Navbar, SideNav, PageContainer } from '@/features/Navigation';

export function Root() {
  return (
    <>
      <Navbar />
      <aside>
        <SideNav />
      </aside>
      <main>
        <PageContainer maxWidth="xl" disableGutters>
          <Outlet />
        </PageContainer>
      </main>
    </>
  );
}
