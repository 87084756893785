/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptanceCriteriaGroupListIAPIResult } from '../models/AcceptanceCriteriaGroupListIAPIResult';
import type { CountryListIAPIResult } from '../models/CountryListIAPIResult';
import type { DocumentIAPIResult } from '../models/DocumentIAPIResult';
import type { DocumentListIAPIResult } from '../models/DocumentListIAPIResult';
import type { DurationListIAPIResult } from '../models/DurationListIAPIResult';
import type { ProductIAPIResult } from '../models/ProductIAPIResult';
import type { ProductListIAPIResult } from '../models/ProductListIAPIResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class ProductService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get list of all supported products
   * @returns ProductListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Product({
    properties,
  }: {
    /**
     * OPTIONAL - The properties used to filter products eg assetType
     */
    properties?: Record<string, string>,
  }): CancelablePromise<ProductListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product',
      query: {
        'properties': properties,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get list of all supported products
   * @returns ProductIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Product1({
    productName,
    properties,
  }: {
    /**
     * An enum representing a product.
     */
    productName: string,
    /**
     * OPTIONAL - The properties used to filter products eg assetType
     */
    properties?: Record<string, string>,
  }): CancelablePromise<ProductIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{productName}',
      path: {
        'productName': productName,
      },
      query: {
        'properties': properties,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets all supported durations for a given product
   * @returns DurationListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductDuration({
    product,
    properties,
  }: {
    /**
     * REQUIRED - Name of the product
     */
    product: string,
    /**
     * OPTIONAL - The properties used to filter product durations eg assetType
     */
    properties?: Record<string, string>,
  }): CancelablePromise<DurationListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/duration',
      path: {
        'product': product,
      },
      query: {
        'properties': properties,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets all supported add-ons for a given product
   * @returns DurationListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductAddOn({
    product,
    properties,
  }: {
    /**
     * REQUIRED - Name of the product
     */
    product: string,
    /**
     * OPTIONAL - The properties used to filter product add-ons eg assetType
     */
    properties?: Record<string, string>,
  }): CancelablePromise<DurationListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/add-on',
      path: {
        'product': product,
      },
      query: {
        'properties': properties,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets supported Passport Countries for the selected product. This is used for passport MRZ Row 2 validation
   * @returns CountryListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductDrivingLicenceIssuingCountry({
    product,
  }: {
    /**
     * REQUIRED - Name of the product
     */
    product: string,
  }): CancelablePromise<CountryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/driving-licence-issuing-country',
      path: {
        'product': product,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets the Acceptance Criteria for the selected product
   * @returns AcceptanceCriteriaGroupListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductAcceptanceCriteria({
    product,
  }: {
    /**
     * REQUIRED - Name of the product
     */
    product: string,
  }): CancelablePromise<AcceptanceCriteriaGroupListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/acceptance-criteria',
      path: {
        'product': product,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets the insurance documents (Policy wording, IPID etc. for the selected product)
   * @returns DocumentListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductDocument({
    product,
    assetType,
    underwriter,
    brandName,
  }: {
    /**
     * REQUIRED - Name of the product
     */
    product: string,
    /**
     * REQUIRED - Type of asset being insured
     */
    assetType?: string,
    /**
     * OPTIONAL - Type of underwriter
     */
    underwriter?: string,
    /**
     * OPTIONAL - Name of the brand
     */
    brandName?: string,
  }): CancelablePromise<DocumentListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/document',
      path: {
        'product': product,
      },
      query: {
        'assetType': assetType,
        'underwriter': underwriter,
        'brandName': brandName,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get an individual insurance document (Policy wording, IPID etc. for the selected product)
   * @returns DocumentIAPIResult Success
   * @throws ApiError
   */
  public getApiV1ProductDocument1({
    product,
    documentType,
    assetType,
    underwriter,
    brandName,
  }: {
    /**
     * REQUIRED - Name of the product
     */
    product: string,
    /**
     * REQUIRED - The document type of the document being downloaded
     */
    documentType: string,
    /**
     * REQUIRED - The asset type of the document being downloaded
     */
    assetType?: string,
    underwriter?: string,
    brandName?: string,
  }): CancelablePromise<DocumentIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/product/{product}/document/{documentType}',
      path: {
        'product': product,
        'documentType': documentType,
      },
      query: {
        'assetType': assetType,
        'underwriter': underwriter,
        'brandName': brandName,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
}
