/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { BaseHttpRequest } from './core/BaseHttpRequest';
import type { OpenAPIConfig } from './core/OpenAPI';
import { AxiosHttpRequest } from './core/AxiosHttpRequest';
import { CustomersService } from './services/CustomersService';
import { CustomerVulnerabilityService } from './services/CustomerVulnerabilityService';
import { DocumentsService } from './services/DocumentsService';
import { IndexService } from './services/IndexService';
import { PaymentMethodService } from './services/PaymentMethodService';
import { PaymentsService } from './services/PaymentsService';
import { PoliciesService } from './services/PoliciesService';
import { QuotesService } from './services/QuotesService';
import { UsersService } from './services/UsersService';
type HttpRequestConstructor = new (config: OpenAPIConfig) => BaseHttpRequest;
export class SupportAPI {
  public readonly customers: CustomersService;
  public readonly customerVulnerability: CustomerVulnerabilityService;
  public readonly documents: DocumentsService;
  public readonly index: IndexService;
  public readonly paymentMethod: PaymentMethodService;
  public readonly payments: PaymentsService;
  public readonly policies: PoliciesService;
  public readonly quotes: QuotesService;
  public readonly users: UsersService;
  public readonly request: BaseHttpRequest;
  constructor(config?: Partial<OpenAPIConfig>, HttpRequest: HttpRequestConstructor = AxiosHttpRequest) {
    this.request = new HttpRequest({
      BASE: config?.BASE ?? '',
      VERSION: config?.VERSION ?? '1',
      WITH_CREDENTIALS: config?.WITH_CREDENTIALS ?? false,
      CREDENTIALS: config?.CREDENTIALS ?? 'include',
      TOKEN: config?.TOKEN,
      USERNAME: config?.USERNAME,
      PASSWORD: config?.PASSWORD,
      HEADERS: config?.HEADERS,
      ENCODE_PATH: config?.ENCODE_PATH,
    });
    this.customers = new CustomersService(this.request);
    this.customerVulnerability = new CustomerVulnerabilityService(this.request);
    this.documents = new DocumentsService(this.request);
    this.index = new IndexService(this.request);
    this.paymentMethod = new PaymentMethodService(this.request);
    this.payments = new PaymentsService(this.request);
    this.policies = new PoliciesService(this.request);
    this.quotes = new QuotesService(this.request);
    this.users = new UsersService(this.request);
  }
}

