/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { OccupationListIAPIResult } from '../models/OccupationListIAPIResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class OccupationService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Gets all Occupations for a product - additional parameter 'WarningType' will return
   * @returns OccupationListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Occupation(): CancelablePromise<OccupationListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/occupation',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets all occupations matching a given pattern
   * @returns OccupationListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Occupation1({
    pattern,
  }: {
    /**
     * REQUIRED - pattern
     */
    pattern: string,
  }): CancelablePromise<OccupationListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/occupation/{pattern}',
      path: {
        'pattern': pattern,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
}
