import { Button, Menu, styled } from '@mui/material';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

export const StyledButton = styled(Button)(({ theme }) => ({
  minWidth: 0,
  padding: 0,
  color: theme.palette.text.primary,
  backgroundColor: 'transparent',

  '&:hover': {
    backgroundColor: 'transparent',
  },

  '& .MuiButton-startIcon': {
    margin: theme.spacing(2),
  },
  '& .MuiButton-endIcon': {
    margin: 0,
  },
}));

export const StyledMenu = styled(Menu)(({ theme }) => ({
  '& .MuiMenu-paper': {
    marginLeft: theme.spacing(10),
    width: '110px',
  },
  '& .MuiMenu-list': {
    fontSize: theme.typography.fontSize,
    fontWeight: theme.typography.fontWeightRegular,
  },
}));

export const StyledAccountCircleIcon = styled(AccountCircleIcon)(({ theme }) => ({
  width: '32px',
  height: '32px',
  color: theme.palette.grey[400],
}));

export const StyledArrowDropDownIcon = styled(ArrowDropDownIcon)(({ theme }) => ({
  width: '24px',
  height: '24px',
  color: theme.palette.grey[500],
}));
