import { Container, styled } from '@mui/material';

export const PageContainer = styled(Container)(({ theme }) => ({
  boxSizing: 'border-box',
  paddingLeft: theme.spacing(20),
  paddingTop: theme.spacing(16),
  width: '100vw',
  [theme.breakpoints.up('xl')]: {
    maxWidth: 'none',
  },
}));
