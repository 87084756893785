import { ReminderStatus } from '@/api/note';
import { StateChipType } from '@/components/ui/presentation/StateChip';

export function getStateChipType(reminderStatus: ReminderStatus): StateChipType {
  switch (reminderStatus) {
    case ReminderStatus.COMPLETE:
      return StateChipType.Success;
    case ReminderStatus.UPCOMING:
      return StateChipType.Info;
    case ReminderStatus.DUE:
      return StateChipType.Warning;
    case ReminderStatus.OVER_DUE:
      return StateChipType.Error;
  }
}
export function getReminderState(reminderStatus?: ReminderStatus) {
  switch (reminderStatus) {
    case ReminderStatus.COMPLETE:
      return 'Complete';
    case ReminderStatus.UPCOMING:
      return 'Upcoming';
    case ReminderStatus.DUE:
      return 'Due';
    case ReminderStatus.OVER_DUE:
      return 'Overdue';
    default:
      return 'No reminder';
  }
}
