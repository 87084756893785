import { Box, styled } from '@mui/material';
import { AccountMenu } from './components/AccountMenu';

const AccountContainer = styled(Box)(() => ({
  display: 'flex',
  alignContent: 'center',
  alignItems: 'center',
}));

export function Account() {
  return (
    <AccountContainer>
      <AccountMenu />
    </AccountContainer>
  );
}
