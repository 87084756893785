/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CancellationQuoteIAPIResult } from '../models/CancellationQuoteIAPIResult';
import type { CancellationQuoteRequest } from '../models/CancellationQuoteRequest';
import type { CancelPolicyResponseIAPIResult } from '../models/CancelPolicyResponseIAPIResult';
import type { DocumentDetailsListIAPIResult } from '../models/DocumentDetailsListIAPIResult';
import type { IAPIResult } from '../models/IAPIResult';
import type { PolicyDocumentIAPIResult } from '../models/PolicyDocumentIAPIResult';
import type { PolicyV1IAPIResult } from '../models/PolicyV1IAPIResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PolicyService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Get a policy by the id.
   * @returns PolicyV1IAPIResult Success
   * @throws ApiError
   */
  public getApiV1Policy({
    policyId,
  }: {
    /**
     * An ID representing a policy
     */
    policyId: number,
  }): CancelablePromise<PolicyV1IAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{policyId}',
      path: {
        'policyId': policyId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get all documents for the current quote.
   * @returns DocumentDetailsListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PolicyDocument({
    policyId,
  }: {
    /**
     * An ID representing a policy
     */
    policyId: number,
  }): CancelablePromise<DocumentDetailsListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{policyId}/document',
      path: {
        'policyId': policyId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get the chosen document for the current quote.
   * @returns PolicyDocumentIAPIResult Success
   * @throws ApiError
   */
  public getApiV1PolicyDocument1({
    policyId,
    documentType,
  }: {
    /**
     * An ID representing a policy
     */
    policyId: number,
    /**
     * The documentType you want to return
     */
    documentType: string,
  }): CancelablePromise<PolicyDocumentIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/policy/{policyId}/document/{documentType}',
      path: {
        'policyId': policyId,
        'documentType': documentType,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Marks the chosen document to be sent for post.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV1PolicyDocumentSendPaperCopy({
    policyId,
    documentType,
  }: {
    /**
     * An ID representing a policy
     */
    policyId: number,
    /**
     * The documentType you want to return
     */
    documentType: string,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/document/{documentType}/send-paper-copy',
      path: {
        'policyId': policyId,
        'documentType': documentType,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Returns a cancellation quote for the given policy.
   * @returns CancellationQuoteIAPIResult Success
   * @throws ApiError
   */
  public postApiV1PolicyCancellationQuote({
    policyId,
    requestBody,
  }: {
    /**
     * An ID representing a policy.
     */
    policyId: number,
    /**
     * A request containing a choosen cancellation datetime.
     */
    requestBody?: CancellationQuoteRequest,
  }): CancelablePromise<CancellationQuoteIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/cancellation-quote',
      path: {
        'policyId': policyId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Cancels a policy using the stored cancellation quote if valid.
   * @returns CancelPolicyResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1PolicyCancellationQuoteCancel({
    policyId,
    cancellationQuoteId,
  }: {
    /**
     * An ID representing a policy.
     */
    policyId: number,
    /**
     * The id of the stored cancellation quote.
     */
    cancellationQuoteId: string,
  }): CancelablePromise<CancelPolicyResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/policy/{policyId}/cancellation-quote/{cancellationQuoteId}/cancel',
      path: {
        'policyId': policyId,
        'cancellationQuoteId': cancellationQuoteId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
        410: `Client Error`,
      },
    });
  }
}
