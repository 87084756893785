import { z } from 'zod';

export const PermissionSchema = z.enum([
  'readanypolicies:support',
  'readanypaymentmethod:support',
  'readanyuser:support',
  'manageusers:support',
  'read:motorpolicy',
  'readany:motorpolicy',
  'adddebt:support',
  'settlebalance:support',
  'reverse:motorpolicy',
  'policyholderaccountupdate:motorpolicy',
  'cancel:motorpolicy',
  'cancelnorefund:motorpolicy',
  'adjust:motorpolicy',
  'readany:note',
  'create:note',
  'update:note',
  'updateotherusers:note',
  'readany:vulnerablecustomer',
  'create:vulnerablecustomer',
  'updateany:vulnerablecustomer',
  'delete:vulnerablecustomer',
  'admin:reminders',
  'readany:reminders',
  'updateany:reminders',
  'createuser:usermanager',
  'updateanyuser:usermanager',
  'archive:user',
  'lock:user',
  'unlock:user',
  'patchfees:motorquote',
  'patchfeebanda:motorquote',
  'patchfeebandb:motorquote',
  'patchfeebandc:motorquote',
  'patchfeebandd:motorquote',
  'patchfeebande:motorquote',
  'patchpremium:motorquote',
]);

export type Permission = z.infer<typeof PermissionSchema>;
