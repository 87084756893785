import {
  DrawerProps as MuiDrawerProps,
  Drawer as MuiDrawer,
  ClickAwayListener,
} from '@mui/material';
import { StyledDrawerContainer } from './Drawer.styles';
import { DrawerContext } from '@/contexts/DrawerContext';

type DrawerProps = MuiDrawerProps & {
  onClose?: () => void;
};

export function Drawer({ children, onClose, ...props }: DrawerProps) {
  const handleClickAway = (event: MouseEvent | TouchEvent) => {
    if (
      event.target instanceof HTMLElement &&
      event.target.classList.contains('MuiDrawer-root')
    ) {
      onClose?.();
    }
  };

  const handleClose = () => {
    onClose?.();
  };

  return (
    <MuiDrawer {...props} onClose={onClose}>
      <DrawerContext.Provider value={{ close: handleClose }}>
        <ClickAwayListener onClickAway={handleClickAway}>
          <StyledDrawerContainer>{children}</StyledDrawerContainer>
        </ClickAwayListener>
      </DrawerContext.Provider>
    </MuiDrawer>
  );
}
