import { AuthProviderProps } from 'react-oidc-context';
import { router } from './router';

const acr_values = 'tenant:motorSupportIdSrv referrer:Dayinsure';

export const oidcConfig: AuthProviderProps = {
  authority: import.meta.env.VITE_APP_AUTH_AUTHORITY,
  client_id: import.meta.env.VITE_APP_AUTH_CLIENT_ID,
  scope: import.meta.env.VITE_APP_AUTH_SCOPE,
  redirect_uri: `${window.origin}/login/redirect`,
  response_type: 'code',
  acr_values,
  extraTokenParams: {
    acr_values,
  },
  onSigninCallback(user) {
    const redirectPath =
      (user?.state as { returnPath: string } | undefined)?.returnPath ?? '/';
    void router.navigate(redirectPath, { replace: true });
  },
};
