/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { SendByPostRequestDto } from '../models/SendByPostRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class DocumentsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns any Success
   * @throws ApiError
   */
  public postApiV1DocumentsSendByPost({
    requestBody,
  }: {
    requestBody?: SendByPostRequestDto,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/documents/send-by-post',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        422: `Client Error`,
      },
    });
  }
}
