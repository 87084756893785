/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ConfirmEmailRequest } from '../models/ConfirmEmailRequest';
import type { ConfirmPasswordResetRequest } from '../models/ConfirmPasswordResetRequest';
import type { CreateUserRequest } from '../models/CreateUserRequest';
import type { CreateUserResponseDto } from '../models/CreateUserResponseDto';
import type { GetIndividualsResponseDto } from '../models/GetIndividualsResponseDto';
import type { GetRolesResponseDto } from '../models/GetRolesResponseDto';
import type { SendPasswordResetRequest } from '../models/SendPasswordResetRequest';
import type { SendVerificationEmailRequest } from '../models/SendVerificationEmailRequest';
import type { UpdateUserRequest } from '../models/UpdateUserRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class UsersService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns GetIndividualsResponseDto Success
   * @throws ApiError
   */
  public getApiV1Users(): CancelablePromise<GetIndividualsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/users',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @returns GetIndividualsResponseDto Success
   * @throws ApiError
   */
  public getApiV1Companies(): CancelablePromise<GetIndividualsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/companies',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @returns GetIndividualsResponseDto Success
   * @throws ApiError
   */
  public getApiV1AdminUsers(): CancelablePromise<GetIndividualsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/users',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @returns CreateUserResponseDto Success
   * @throws ApiError
   */
  public postApiV1AdminUsers({
    requestBody,
  }: {
    requestBody?: CreateUserRequest,
  }): CancelablePromise<CreateUserResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/admin/users',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public putApiV1AdminUsers({
    requestBody,
  }: {
    requestBody?: UpdateUserRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/admin/users',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @returns GetRolesResponseDto Success
   * @throws ApiError
   */
  public getApiV1AdminUsersRoles(): CancelablePromise<GetRolesResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/admin/users/roles',
      errors: {
        401: `Unauthorized`,
        403: `Forbidden`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public postApiV1UsersConfirmEmail({
    requestBody,
  }: {
    requestBody?: ConfirmEmailRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/confirm-email',
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public postApiV1UsersSendPasswordReset({
    email,
    requestBody,
  }: {
    email: string,
    requestBody?: SendPasswordResetRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/{email}/send-password-reset',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public postApiV1UsersConfirmPasswordReset({
    personId,
    requestBody,
  }: {
    personId: string,
    requestBody?: ConfirmPasswordResetRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/{personId}/confirm-password-reset',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public postApiV1UsersSendEmailVerification({
    email,
    requestBody,
  }: {
    email: string,
    requestBody?: SendVerificationEmailRequest,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/users/{email}/send-email-verification',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
}
