/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddNoteResponseDto } from '../models/AddNoteResponseDto';
import type { AttachmentReceivingMethod } from '../models/AttachmentReceivingMethod';
import type { AttachmentType } from '../models/AttachmentType';
import type { CommunicationMethod } from '../models/CommunicationMethod';
import type { CorrespondenceType } from '../models/CorrespondenceType';
import type { GetNotesResponseDto } from '../models/GetNotesResponseDto';
import type { GetReminderGroupsResponseDto } from '../models/GetReminderGroupsResponseDto';
import type { GetRemindersResponseDto } from '../models/GetRemindersResponseDto';
import type { NoteInitiator } from '../models/NoteInitiator';
import type { NoteType } from '../models/NoteType';
import type { ReassignRemindersRequestDto } from '../models/ReassignRemindersRequestDto';
import type { ReminderFilterType } from '../models/ReminderFilterType';
import type { UpdateNoteResponseDto } from '../models/UpdateNoteResponseDto';
import type { UpdateReminderDateRequestDto } from '../models/UpdateReminderDateRequestDto';
import type { UpdateReminderDateResponseDto } from '../models/UpdateReminderDateResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class NotesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns GetNotesResponseDto Success
   * @throws ApiError
   */
  public getApiV1Notes({
    parentId,
  }: {
    parentId?: string,
  }): CancelablePromise<GetNotesResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/notes',
      query: {
        'ParentId': parentId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns AddNoteResponseDto Success
   * @throws ApiError
   */
  public postApiV1Notes({
    formData,
  }: {
    formData?: {
      ParentId?: string;
      CustomerId?: string;
      IsPolicyNote?: boolean;
      NoteType?: NoteType;
      Initiator?: NoteInitiator;
      CommunicationMethod?: CommunicationMethod;
      CorrespondenceType?: CorrespondenceType;
      Summary?: string;
      Description?: string;
      DateOfReminder?: string;
      Assignee?: string;
      AssigneeTeam?: string;
      AttachmentFiles?: Array<Blob>;
      AttachmentTypes?: Array<AttachmentType>;
      AttachmentReceivingMethods?: Array<AttachmentReceivingMethod>;
      NewAttachmentsAcceptedAsProof?: Array<boolean>;
    },
  }): CancelablePromise<AddNoteResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/notes',
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns UpdateNoteResponseDto Success
   * @throws ApiError
   */
  public patchApiV1Notes({
    id,
    formData,
  }: {
    id: string,
    formData?: {
      NoteType?: NoteType;
      Initiator?: NoteInitiator;
      CommunicationMethod?: CommunicationMethod;
      CorrespondenceType?: CorrespondenceType;
      Summary?: string;
      DeletedAttachments?: Array<string>;
      ExistingAttachmentsToggleAcceptedAsProof?: Array<string>;
      Description?: string;
      DateOfReminder?: string;
      Assignee?: string;
      AssigneeTeam?: string;
      AttachmentFiles?: Array<Blob>;
      AttachmentTypes?: Array<AttachmentType>;
      AttachmentReceivingMethods?: Array<AttachmentReceivingMethod>;
      NewAttachmentsAcceptedAsProof?: Array<boolean>;
    },
  }): CancelablePromise<UpdateNoteResponseDto> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/notes/{id}',
      path: {
        'id': id,
      },
      formData: formData,
      mediaType: 'multipart/form-data',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        409: `Conflict`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public getApiV1NotesAttachments({
    filePath,
  }: {
    filePath: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/notes/attachments/{FilePath}',
      path: {
        'FilePath': filePath,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public patchApiV1NotesCompleteReminder({
    id,
  }: {
    id: string,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/notes/complete-reminder/{Id}',
      path: {
        'Id': id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @returns UpdateReminderDateResponseDto Success
   * @throws ApiError
   */
  public patchApiV1NotesRemindersUpdateDate({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: UpdateReminderDateRequestDto,
  }): CancelablePromise<UpdateReminderDateResponseDto> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/notes/reminders/update-date/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @returns GetRemindersResponseDto Success
   * @throws ApiError
   */
  public getApiV1NotesReminders({
    filterType,
    upcomingDate,
  }: {
    filterType?: ReminderFilterType,
    upcomingDate?: string,
  }): CancelablePromise<GetRemindersResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/notes/reminders',
      query: {
        'FilterType': filterType,
        'UpcomingDate': upcomingDate,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns GetRemindersResponseDto Success
   * @throws ApiError
   */
  public getApiV1NotesAdminReminders({
    dateFrom,
    dateTo,
    filterType,
    individual,
    assigned,
    team,
  }: {
    dateFrom?: string,
    dateTo?: string,
    filterType?: ReminderFilterType,
    individual?: string,
    assigned?: boolean,
    team?: string,
  }): CancelablePromise<GetRemindersResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/notes/admin/reminders',
      query: {
        'DateFrom': dateFrom,
        'DateTo': dateTo,
        'FilterType': filterType,
        'Individual': individual,
        'Assigned': assigned,
        'Team': team,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns any Success
   * @throws ApiError
   */
  public patchApiV1NotesAdminReminders({
    requestBody,
  }: {
    requestBody?: ReassignRemindersRequestDto,
  }): CancelablePromise<any> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/notes/admin/reminders',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns GetReminderGroupsResponseDto Success
   * @throws ApiError
   */
  public getApiV1NotesRemindersGroups(): CancelablePromise<GetReminderGroupsResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/notes/reminders/groups',
      errors: {
        400: `Bad Request`,
      },
    });
  }
}
