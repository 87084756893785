import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useApiClient } from '../contexts/useApiClient';
import { queryKeys } from '@/helpers/queryKeys';

type UseChangeReminderDateMutationProps = {
  onSuccess?: () => void;
};

export function useChangeReminderDateMutation({
  onSuccess,
}: UseChangeReminderDateMutationProps) {
  const queryClient = useQueryClient();
  const apiClient = useApiClient();

  return useMutation({
    mutationFn: ({ reminderId, dueDate }: { reminderId: string; dueDate: Date }) =>
      apiClient.note.notes.patchApiV1NotesRemindersUpdateDate({
        id: reminderId,
        requestBody: { dateOfReminder: dueDate.toISOString() },
      }),
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: queryKeys.adminReminders(),
      });
      await queryClient.invalidateQueries({
        queryKey: queryKeys.individualReminders(),
      });
      onSuccess?.();
    },
  });
}
