import { AppBar, Toolbar, styled } from '@mui/material';

export const StyledAppBar = styled(AppBar)(({ theme }) => ({
  height: '100vh',
  width: '80px',
  left: '0',
  paddingTop: theme.spacing(4.5),
  paddingBottom: theme.spacing(4.5),
  display: 'flex',
  alignItems: 'center',
  background: 'rgb(0, 14, 51)',
  boxSizing: 'border-box',
}));

export const StyledToolbar = styled(Toolbar)(({ theme }) => ({
  flexDirection: 'column',
  marginTop: theme.spacing(11.5),
  boxSizing: 'border-box',
  gap: '20px',
  width: 'inherit',
}));
