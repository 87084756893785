import { PropsWithChildren, useEffect, useState } from 'react';
import { createPortal } from 'react-dom';

export function MountNavbarContent({ children }: PropsWithChildren) {
  const [navbarContentEl, setNavbarContentEl] = useState<HTMLElement | null>();

  useEffect(() => {
    const element = document.getElementById('navbar-content');
    setNavbarContentEl(element);
  }, []);

  return navbarContentEl && createPortal(children, navbarContentEl);
}
