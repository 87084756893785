import { styled } from '@mui/material';
import { ReactNode } from 'react';

type IconProps = {
  isActive: boolean;
};

type SideNavIconProps = {
  children: ReactNode;
  isActive: boolean;
};

const IconContainer = styled('div')(({ isActive }: IconProps) => ({
  width: '48px',
  height: '48px',
  background: isActive ? 'rgba(255, 255, 255, .1)' : 'transparent',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  borderRadius: '8px',
}));

const GlassWrapper = styled('div')(({ isActive }: IconProps) => ({
  opacity: isActive ? '1' : '.4',
  ':hover': {
    opacity: isActive ? '1' : '.6',
  },
  color: '#fff',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
}));

export function SideNavIcon({ children, isActive }: SideNavIconProps) {
  return (
    <IconContainer isActive={isActive}>
      <GlassWrapper isActive={isActive}>{children}</GlassWrapper>
    </IconContainer>
  );
}
