import { Box, Button } from '@mui/material';
import { FormProvider, useForm } from 'react-hook-form';
import * as z from 'zod';
import { isFuture } from 'date-fns';
import { zodResolver } from '@hookform/resolvers/zod';
import invariant from 'tiny-invariant';
import { LoadingButton } from '@mui/lab';
import { StyledModalFooter } from '@/components/ui/styles/StyledModal';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { validationMessages } from '@/helpers/textHelper';
import { FormDateTimePicker } from '@/components/ui/forms/FormDateTimePicker';

type ChangeDateModalContentProps = {
  isError: boolean;
  isPending: boolean;
  onConfirm: (data: { dueDate: Date }) => void;
  onCancel: () => void;
};

const changeDateFormSchema = z.object({
  dueDate: z
    .nullable(z.date())
    .refine(value => Boolean(value), validationMessages.required)
    .refine(value => !value || isFuture(value), 'Date has to be in the future'),
});

type ChangeDateFormSchema = z.infer<typeof changeDateFormSchema>;

function useChangeDateForm() {
  return useForm({
    defaultValues: {
      dueDate: null,
    },
    resolver: zodResolver(changeDateFormSchema),
  });
}

export function ChangeDateModalContent({
  isError,
  isPending,
  onConfirm,
  onCancel,
}: ChangeDateModalContentProps) {
  const form = useChangeDateForm();
  const { handleSubmit } = form;

  const onFormSubmit = (data: ChangeDateFormSchema) => {
    const { dueDate } = data;
    invariant(dueDate, 'Due date should be validated to not be a null');
    onConfirm({ dueDate });
  };

  return (
    <Box p={6}>
      <FormProvider {...form}>
        <form onSubmit={handleSubmit(onFormSubmit)}>
          {isError && <DrawerError />}
          <Box mt={3} mb={9}>
            <FormDateTimePicker
              testId="reminders_due-date-datetimepicker"
              name="dueDate"
              label="Reminder due date/time"
              minDate={new Date()}
            />
          </Box>
          <StyledModalFooter>
            <LoadingButton
              data-testid="reminders_change-date-submitbutton"
              type="submit"
              variant="contained"
              color="secondary"
              loading={isPending}
            >
              Change date
            </LoadingButton>
            <Button variant="outlined" color="inherit" onClick={onCancel}>
              Cancel
            </Button>
          </StyledModalFooter>
        </form>
      </FormProvider>
    </Box>
  );
}
