import { PaletteOptions } from '@mui/material';
import { grey } from './colors';

export const palette: PaletteOptions = {
  primary: {
    main: '#00A5E1',
    light: '#00A5E11A',
  },
  secondary: {
    main: '#EB2991',
    light: '#EB29911A',
  },
  success: {
    main: '#22C55E',
    light: '#22C55E1A',
  },
  warning: {
    main: '#F59E0B',
    light: '#F59E0B1A',
  },
  error: {
    main: '#EF5350',
  },
  text: {
    primary: grey[700],
  },
  action: {
    active: grey[500],
    selectedOpacity: 0.1,
  },
  background: {
    default: grey[100],
  },
  info: {
    main: '#00A5E1',
    light: '#00A5E11A',
  },
  grey,
};
