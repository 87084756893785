import { useState } from 'react';
import { MenuItem } from '@mui/material';
import { useAuth } from 'react-oidc-context';
import { StyledAccountCircleIcon, StyledButton, StyledMenu } from './AccountMenu.styles';

export function AccountMenu() {
  const auth = useAuth();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = () => {
    void auth.signoutRedirect({
      id_token_hint: auth.user?.id_token,
      post_logout_redirect_uri: `${window.origin}/logout`,
    });
  };

  return (
    <>
      <StyledButton
        disableRipple
        startIcon={<StyledAccountCircleIcon />}
        id="button"
        aria-controls={open ? '-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
      />
      <StyledMenu
        id="menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'button',
        }}
      >
        <MenuItem data-testid="navigation_account-logout-menuitem" onClick={handleLogout}>
          Logout
        </MenuItem>
      </StyledMenu>
    </>
  );
}
