import { TypographyProps, Typography, useTheme } from '@mui/material';

export function FieldError({ children, ...props }: TypographyProps) {
  const theme = useTheme();

  return (
    <Typography color={theme.palette.error.main} variant="caption" ml={3} {...props}>
      {children}
    </Typography>
  );
}
