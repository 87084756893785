import { SkeletonLoader } from '../loaders/SkeletonLoader';
import { StyledDrawerContent } from '../styles/StyledDrawerContent';

export function DrawerLoading() {
  return (
    <StyledDrawerContent>
      <SkeletonLoader />
    </StyledDrawerContent>
  );
}
