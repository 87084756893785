import { PropsWithChildren, useCallback, useState } from 'react';
import { SnackbarAlert } from './SnackbarAlert';
import { Alert, SnackbarAlertContext } from '@/hooks/contexts/useSnackbarAlertContext';

export function SnackbarAlertContextProvider({ children }: PropsWithChildren) {
  const [alert, setAlert] = useState<Alert>({ message: '' });
  const close = useCallback(() => setAlert({ message: '' }), [setAlert]);

  return (
    <SnackbarAlertContext.Provider value={{ alert, setAlert, close }}>
      {alert.message ? (
        <SnackbarAlert
          message={alert.message}
          severity={alert.severity ?? 'success'}
          onClose={close}
        />
      ) : null}
      {children}
    </SnackbarAlertContext.Provider>
  );
}
