import { useEffect, useMemo } from 'react';
import { useSnackbarAlertContext } from './contexts/useSnackbarAlertContext';

export function useSnackbarAlert({ closeOnUnmount = true } = {}) {
  const { alert, setAlert, close } = useSnackbarAlertContext();
  const memoizedClose = useMemo(() => close, []);

  useEffect(() => {
    return () => {
      if (!!alert?.message && closeOnUnmount) {
        memoizedClose();
      }
    };
  }, [closeOnUnmount, memoizedClose, alert?.message]);

  return {
    alert,
    setAlert,
    close: memoizedClose,
  };
}
