import { Tabs, Tab, styled } from '@mui/material';
import { UiSpacing } from '@/models/UiSpacing';

export const StyledNavbarTabs = styled(Tabs)(({ theme }) => ({
  height: theme.spacing(UiSpacing.NavbarHeight),
  minWidth: 160,
}));

export const StyledNavbarTab = styled(Tab)(({ theme }) => ({
  minHeight: theme.spacing(UiSpacing.NavbarHeight),

  [theme.breakpoints.down('lg')]: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
  },
}));
