import { Box, styled } from '@mui/material';

export const StyledReminderDetailWrapper = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  height: '100vh',
  width: 'inherit',
  backgroundColor: theme.palette.background.paper,
}));

export const StyledReminderDetailContent = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
  width: '100%',
}));
