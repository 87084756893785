import { Box, styled } from '@mui/material';

export const StyledModalFooter = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(6),
  paddingTop: theme.spacing(6),
  borderTop: `1px solid ${theme.palette.divider}`,
  '& > *': {
    flex: 1,
  },
}));
