/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AbiVehicleIAPIResult } from '../models/AbiVehicleIAPIResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AbiVehicleService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Gets a vehicle given an ABI Code via a third party and returns vehicle details
   * @returns AbiVehicleIAPIResult Success
   * @throws ApiError
   */
  public getApiV1AbiVehicle({
    abiCode,
  }: {
    /**
     * Vehicle ABI Code
     */
    abiCode: string,
  }): CancelablePromise<AbiVehicleIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/abi-vehicle/{abiCode}',
      path: {
        'abiCode': abiCode,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
}
