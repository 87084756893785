import { Alert } from '@mui/material';
import { IndividualRemindersAccordionHeader } from '../IndividualRemindersAccordionHeader';
import { IndividualRemindersAccordionContent } from '../IndividualRemindersAccordionContent';
import { StyledReminderDetailContent } from './IndividualRemindersContent.styles';
import { ReminderFilterType } from '@/api/note';
import { useIndividualRemindersQuery } from '@/hooks/queries/useIndividualRemindersQuery';
import { DrawerError } from '@/components/ui/drawers/DrawerError';
import { DrawerLoading } from '@/components/ui/drawers/DrawerLoading';
import { AccordionList, AccordionListItem } from '@/components/ui/lists/AccordionList';

type IndividualRemindersContentProps = {
  filterParam: ReminderFilterType;
};

export function IndividualRemindersContent({
  filterParam,
}: IndividualRemindersContentProps) {
  const { reminders, isError, isPending, refetch } = useIndividualRemindersQuery({
    filterType: filterParam,
  });

  if (isError) return <DrawerError />;
  if (isPending) return <DrawerLoading />;

  if (!reminders?.length) {
    return (
      <StyledReminderDetailContent padding={6}>
        <Alert severity="info">No reminders are currently set</Alert>
      </StyledReminderDetailContent>
    );
  }
  const items: AccordionListItem[] = reminders?.map(reminder => ({
    key: reminder.reminderId,
    header: <IndividualRemindersAccordionHeader reminder={reminder} />,
    content: (
      <IndividualRemindersAccordionContent reminder={reminder} refetch={refetch} />
    ),
  }));

  return <AccordionList items={items} />;
}
