import {
  Box,
  ModalProps as MuiModalProps,
  Modal as MuiModal,
  Stack,
  Typography,
  styled,
} from '@mui/material';
import { CloseIconButton } from '@/components/ui/buttons/CloseIconButton';

const ModalContainer = styled(Box)(({ theme }) => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  maxHeight: '90vh',
  overflowY: 'auto',
  background: theme.palette.background.paper,
  borderRadius: theme.shape.borderRadius * 2,
}));

const ModalHeader = styled(Stack)(({ theme }) => ({
  position: 'sticky',
  top: 0,
  zIndex: theme.zIndex.modal,
  background: theme.palette.background.paper,
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',
  padding: theme.spacing(6),
  borderBottom: `1px solid ${theme.palette.grey[200]}`,
}));

const ModalTitle = styled(Typography)(({ theme }) => ({
  ...theme.typography.h6,
}));

type ModalProps = MuiModalProps & {
  title: string;
  width?: number | string;
};

export function Modal({ onClose, title, children, width = 768, ...props }: ModalProps) {
  return (
    <MuiModal onClose={onClose} aria-labelledby="modal-title" {...props}>
      <ModalContainer width={width}>
        <ModalHeader>
          <ModalTitle id="modal-title">{title}</ModalTitle>
          <CloseIconButton
            testId="ui_modal-close-button"
            onClose={e => onClose?.(e, 'escapeKeyDown')}
          />
        </ModalHeader>
        {children}
      </ModalContainer>
    </MuiModal>
  );
}
