/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { StringListIAPIResult } from '../models/StringListIAPIResult';
import type { VehicleFuelType } from '../models/VehicleFuelType';
import type { VehicleTransmissionType } from '../models/VehicleTransmissionType';
import type { VehicleType } from '../models/VehicleType';
import type { VehicleTypeModelsIAPIResult } from '../models/VehicleTypeModelsIAPIResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class VehicleTypeService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Gets a list of vehicle makes given a vehicle type
   * @returns StringListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1VehicleTypeMake({
    vehicleType,
    registrationYear,
  }: {
    /**
     * REQUIRED - Vehicle type
     */
    vehicleType: VehicleType,
    /**
     * OPTIONAL - Vehicle Registration Year
     */
    registrationYear?: number,
  }): CancelablePromise<StringListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle-type/{vehicleType}/make',
      path: {
        'vehicleType': vehicleType,
      },
      query: {
        'registrationYear': registrationYear,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets a list of vehicle models given a make and vehicle type
   * @returns VehicleTypeModelsIAPIResult Success
   * @throws ApiError
   */
  public getApiV1VehicleTypeMakeModel({
    vehicleType,
    make,
    fuelType,
    transmission,
    registrationYear,
  }: {
    /**
     * REQUIRED - Vehicle type
     */
    vehicleType: VehicleType,
    /**
     * REQUIRED - Vehicle make
     */
    make: string,
    /**
     * OPTIONAL - Vehicle fuel type
     */
    fuelType?: VehicleFuelType,
    /**
     * OPTIONAL - Vehicle transmission
     */
    transmission?: VehicleTransmissionType,
    /**
     * OPTIONAL - Vehicle Registration Year
     */
    registrationYear?: number,
  }): CancelablePromise<VehicleTypeModelsIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle-type/{vehicleType}/make/{make}/model',
      path: {
        'vehicleType': vehicleType,
        'make': make,
      },
      query: {
        'fuelType': fuelType,
        'transmission': transmission,
        'registrationYear': registrationYear,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
}
