import { Grid, List, ListItem, ListProps, Skeleton } from '@mui/material';

type SkeletonLoaderProps = ListProps & { repeat?: number };

export function SkeletonLoader({ repeat = 5, ...props }: SkeletonLoaderProps) {
  const items = Array.from({ length: repeat }, (_, index) => (
    <ListItem key={index} disableGutters data-testid="skeleton-loader">
      <Grid container spacing={3}>
        <Grid item xs={4}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </Grid>
        <Grid item xs={8}>
          <Skeleton variant="text" sx={{ fontSize: '1rem' }} />
        </Grid>
      </Grid>
    </ListItem>
  ));

  return (
    <List
      {...props}
      sx={{ width: '100%', ...props.sx }}
      data-testid="skeleton"
      role="progressbar"
      aria-valuetext="Loading"
      aria-busy="true"
    >
      {items}
    </List>
  );
}
