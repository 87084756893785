/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Dayinsure_Common_Contracts_MotorPolicy_Enums_PolicyStatus {
  UPCOMING = 'Upcoming',
  ACTIVE = 'Active',
  EXPIRED = 'Expired',
  CANCELLED = 'Cancelled',
  VOIDED = 'Voided',
}
