import { Badge, Button, FormControl, styled } from '@mui/material';
import { ChangeEventHandler, useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { SearchSchema } from '../../hooks/useSearchForm';
import { FilterType } from '@/api/support';
import { FormTextField } from '@/components/ui/forms/FormTextField';
import { FormComboBox } from '@/components/ui/forms/FormComboBox';

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  margin: `0 ${theme.spacing(2)}`,

  [theme.breakpoints.down('lg')]: {
    margin: `0 ${theme.spacing(1)}`,

    '.MuiInputBase-input': {
      paddingRight: theme.spacing(2),
      paddingLeft: theme.spacing(2),
    },
  },
}));

const filterOptions = [
  { code: FilterType.VEHICLE_REGISTRATION, description: 'Vehicle Registration' },
  { code: FilterType.EMAIL, description: 'Email address' },
  { code: FilterType.POSTCODE, description: 'Postcode' },
];

export type SearchFiltersProps = {
  filters: { filterValue?: string; filterType?: FilterType | null };
  onFiltersChange: (filters: { keyword: string; type: FilterType | null }) => void;
};

export function SearchFilters({ filters, onFiltersChange }: SearchFiltersProps) {
  const { setValue, getValues } = useFormContext<SearchSchema>();
  const [hasAppliedFilters, setHasAppliedFilters] = useState(false);

  const filtersCount = hasAppliedFilters
    ? [filters.filterValue, filters.filterType].filter(filter => Boolean(filter)).length
    : 0;

  const handleKeywordChange: ChangeEventHandler<HTMLInputElement> = event => {
    const value = event.target.value;
    if (value.match(/^[a-z0-9._@\s'-]*$/i)) {
      setValue('keyword', value);
    }
  };

  const handleClearFilters = () => {
    setValue('keyword', '');
    setValue('type', null);
    onFiltersChange({ keyword: '', type: null });
    setHasAppliedFilters(false);
  };

  const handleSearch = () => {
    const [keyword, type] = getValues(['keyword', 'type']);
    onFiltersChange({ keyword, type: type?.code ?? null });
    setHasAppliedFilters(true);
  };

  return (
    <>
      <StyledFormControl sx={{ maxWidth: 300 }} fullWidth>
        <FormTextField
          name="keyword"
          placeholder="Keyword"
          inputProps={{ 'aria-label': 'Filter value' }}
          onChange={handleKeywordChange}
        />
      </StyledFormControl>
      <FormComboBox
        name="type"
        sx={{ maxWidth: 200, width: '100%' }}
        label="Filter"
        options={filterOptions}
      />
      <Button sx={{ mx: { xs: 1, lg: 2 } }} onClick={handleSearch}>
        Search
      </Button>
      {filtersCount > 0 && (
        <Badge badgeContent={filtersCount} color="secondary">
          <Button
            color="secondary"
            size="small"
            sx={{ mx: { xs: 1, lg: 2 } }}
            onClick={handleClearFilters}
          >
            Clear filters
          </Button>
        </Badge>
      )}
    </>
  );
}
