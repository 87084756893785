import { Outlet } from 'react-router-dom';
import { FormProvider } from 'react-hook-form';
import { useEffect, useState } from 'react';
import { useSearchForm } from './hooks/useSearchForm';
import { DateRangeButtonGroup } from './components/DateRangeButtonGroup';
import { SearchFilters, SearchFiltersProps } from './components/SearchFilters';
import { SearchTabs } from './components/SearchTabs';
import { FilterType } from '@/api/support';
import { MountNavbarContent } from '@/components/portals/MountNavbarContent';

export type SearchContextValue = {
  filters: {
    dateFrom?: string;
    dateTo?: string;
    filterValue?: string;
    filterType?: FilterType;
  };
};

const getSearchFiltersByDate = (date: Date | null): string | undefined =>
  date && isFinite(+date) ? date.toISOString() : undefined;

const getSearchFiltersByKeyword = ({
  keyword,
  type,
}: {
  keyword: string;
  type: FilterType | null;
}): SearchContextValue['filters'] => ({
  filterValue: keyword ? keyword : undefined,
  filterType: Object.values(FilterType).includes(type as FilterType)
    ? (type as FilterType)
    : undefined,
});

export function Search() {
  const form = useSearchForm();
  const { trigger, watch } = form;
  const { dateFrom, dateTo } = watch();
  const [filters, setFilters] = useState<SearchContextValue['filters']>({
    dateFrom: getSearchFiltersByDate(dateFrom),
    dateTo: getSearchFiltersByDate(dateTo),
  });

  const onFiltersChange: SearchFiltersProps['onFiltersChange'] = ({ keyword, type }) =>
    setFilters({ ...filters, ...getSearchFiltersByKeyword({ keyword, type }) });

  useEffect(() => {
    (async () => {
      const isValid = await trigger();

      if (isValid) {
        setFilters({
          ...filters,
          dateFrom: getSearchFiltersByDate(dateFrom),
          dateTo: getSearchFiltersByDate(dateTo),
        });
      }
    })();
  }, [dateFrom, dateTo]);

  return (
    <FormProvider {...form}>
      <MountNavbarContent>
        <SearchTabs />
        <DateRangeButtonGroup />
        <SearchFilters filters={filters} onFiltersChange={onFiltersChange} />
      </MountNavbarContent>
      <Outlet context={{ filters } as SearchContextValue} />
    </FormProvider>
  );
}
