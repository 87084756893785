/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { CreateMotorQuoteRequestDto } from '../models/CreateMotorQuoteRequestDto';
import type { CreateMotorQuoteResponseDto } from '../models/CreateMotorQuoteResponseDto';
import type { GetMotorQuoteResponseDto } from '../models/GetMotorQuoteResponseDto';
import type { UpdateMotorQuoteRequestDto } from '../models/UpdateMotorQuoteRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class MotorQuoteService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns CreateMotorQuoteResponseDto Success
   * @throws ApiError
   */
  public postApiV1MotorQuote({
    requestBody,
  }: {
    requestBody?: CreateMotorQuoteRequestDto,
  }): CancelablePromise<CreateMotorQuoteResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/motor-quote',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns GetMotorQuoteResponseDto Success
   * @throws ApiError
   */
  public getApiV1MotorQuote({
    id,
  }: {
    id: string,
  }): CancelablePromise<GetMotorQuoteResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/motor-quote/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public putApiV1MotorQuote({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: UpdateMotorQuoteRequestDto,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/motor-quote/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
        422: `Client Error`,
      },
    });
  }
}
