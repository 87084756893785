import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
import { IconButton } from '@mui/material';
import { MouseEventHandler } from 'react';

type CloseIconButtonProps = {
  testId?: string;
  onClose: MouseEventHandler<HTMLButtonElement>;
};

export function CloseIconButton({ testId, onClose }: CloseIconButtonProps) {
  return (
    <IconButton data-testid={testId} aria-label="close" tabIndex={1} onClick={onClose}>
      <CloseOutlinedIcon />
    </IconButton>
  );
}
