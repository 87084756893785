/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { VehicleIAPIResult } from '../models/VehicleIAPIResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class VehicleService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Looks up a Vehicle using a third party provider given a vehicle registration
   * @returns VehicleIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Vehicle({
    vehicleRegistration,
  }: {
    /**
     * Vehicle Registration Mark
     */
    vehicleRegistration: string,
  }): CancelablePromise<VehicleIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/vehicle/{vehicleRegistration}',
      path: {
        'vehicleRegistration': vehicleRegistration,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
}
