import { styled } from '@mui/material';
import { EverydayinsureLogo } from './EverydayinsureLogo';

const StyledNavbar = styled('nav')(() => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  height: '64px',
  background: '#000E33',
}));

export function EverydayinsureNavbar() {
  return (
    <StyledNavbar>
      <EverydayinsureLogo />
    </StyledNavbar>
  );
}
