import { Stack } from '@mui/material';
import { PropsWithChildren } from 'react';
import { StyledDrawerContent } from './Drawer.styles';

export function DrawerContent({ children }: PropsWithChildren) {
  return (
    <StyledDrawerContent>
      <Stack position="relative" width="100%" height="100%" direction="column">
        {children}
      </Stack>
    </StyledDrawerContent>
  );
}
