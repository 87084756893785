import { Grid, ListItem, ListItemProps, Typography, styled } from '@mui/material';

export const StyledListRowContent = styled('div')(({ theme }) => ({
  ...theme.typography.body2,
  color: theme.palette.grey[500],
}));

type ListRowProps = ListItemProps & {
  label: string;
  alignContentRight?: boolean;
};

export function ListRow({
  children,
  label,
  alignContentRight = false,
  ...props
}: ListRowProps) {
  const hasChildren = children != null && children !== false;
  return (
    <ListItem disableGutters aria-label={label} {...props}>
      <Grid container spacing={3}>
        <Grid item xs={5}>
          <Typography variant="body2">{label}</Typography>
        </Grid>
        <Grid
          item
          xs={7}
          {...(alignContentRight
            ? { sx: { display: 'flex', justifyContent: 'flex-end' } }
            : {})}
        >
          <StyledListRowContent>{hasChildren ? children : '-'}</StyledListRowContent>
        </Grid>
      </Grid>
    </ListItem>
  );
}
