import { styled, ToggleButtonGroup, ToggleButton } from '@mui/material';
import { useFormContext } from 'react-hook-form';
import { SearchSchema } from '../../hooks/useSearchForm';
import { DateRangePopover } from './components/DateRangePopover';
import {
  DateRange,
  DateRangeType,
  useDateRangeButtonGroup,
} from './hooks/useDateRangeButtonGroup';

const StyledToggleButton = styled(ToggleButton)(({ theme }) => ({
  padding: theme.spacing(1.5, 3),
  fontSize: theme.typography.fontSize,
  fontWeight: theme.typography.fontWeightRegular,

  [theme.breakpoints.down('lg')]: {
    padding: theme.spacing(1.5),
  },
}));

export function DateRangeButtonGroup() {
  const { setValue } = useFormContext<SearchSchema>();
  const onDateRangeTypeChange = (dateRange: DateRange) => {
    const [dateFrom, dateTo] = dateRange;

    setValue('dateFrom', dateFrom);
    setValue('dateTo', dateTo);
  };
  const {
    dateRangeType,
    popoverId,
    isPopoverOpen,
    popoverAnchorEl,
    handleDateRangeTypeChange,
    closePopover,
  } = useDateRangeButtonGroup(onDateRangeTypeChange);

  return (
    <>
      <ToggleButtonGroup
        sx={{ mx: { xs: 1, lg: 2 } }}
        size="small"
        value={dateRangeType}
        exclusive
        onChange={handleDateRangeTypeChange}
      >
        <StyledToggleButton value={DateRangeType.Today}>Today</StyledToggleButton>
        <StyledToggleButton value={DateRangeType.Week}>Week</StyledToggleButton>
        <StyledToggleButton value={DateRangeType.Month}>Month</StyledToggleButton>
        <StyledToggleButton value={DateRangeType.Range} aria-describedby={popoverId}>
          Range
        </StyledToggleButton>
        <StyledToggleButton value={DateRangeType.All}>All</StyledToggleButton>
      </ToggleButtonGroup>
      <DateRangePopover
        id={popoverId}
        open={isPopoverOpen}
        anchorEl={popoverAnchorEl}
        onClose={closePopover}
      />
    </>
  );
}
