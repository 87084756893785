import { endOfDay, startOfDay, startOfMonth, subDays } from 'date-fns';
import { useState } from 'react';
import { getDate } from '@/helpers/dateHelper';

export enum DateRangeType {
  Today = 'today',
  Week = 'week',
  Month = 'month',
  Range = 'range',
  All = 'all',
}

export type DateRange = [Date | null, Date | null];

const getDateRangeByType = (dateRangeType: DateRangeType): DateRange => {
  const now = getDate();
  switch (dateRangeType) {
    case DateRangeType.Today:
      return [startOfDay(now), endOfDay(now)];
    case DateRangeType.Week:
      return [startOfDay(subDays(now, 7)), endOfDay(now)];
    case DateRangeType.Month:
      return [startOfMonth(now), endOfDay(now)];
    case DateRangeType.Range:
      throw new Error('DateRangeType.Range does not have predefined range.');
    case DateRangeType.All:
      return [null, null];
  }
};

export function useDateRangeButtonGroup(
  onDateRangeTypeChange: (dateRange: DateRange) => void,
) {
  const [dateRangeType, setDateRangeType] = useState<DateRangeType>(DateRangeType.Today);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState<HTMLElement | null>(null);

  const isPopoverOpen = Boolean(popoverAnchorEl);
  const popoverId = isPopoverOpen ? 'date-range-popover' : undefined;

  const openPopover = (element: HTMLElement) => {
    setPopoverAnchorEl(element);
  };

  const closePopover = () => {
    setPopoverAnchorEl(null);
  };

  const handleDateRangeTypeChange = (
    event: React.MouseEvent<HTMLElement>,
    newDateRangeType: DateRangeType | null,
  ) => {
    if (newDateRangeType) {
      if (newDateRangeType !== DateRangeType.Range) {
        const newDateRange = getDateRangeByType(newDateRangeType);
        onDateRangeTypeChange(newDateRange);
      }

      setDateRangeType(newDateRangeType);
    }

    const shouldOpenPopover =
      newDateRangeType === DateRangeType.Range ||
      (!newDateRangeType && dateRangeType === DateRangeType.Range);

    if (shouldOpenPopover) {
      openPopover(event.currentTarget);
    }
  };

  return {
    dateRangeType,
    popoverId,
    isPopoverOpen,
    popoverAnchorEl,
    handleDateRangeTypeChange,
    closePopover,
  };
}
