import { GlobalStyles as MuiGlobalStyles } from '@mui/material';

export function GlobalStyles() {
  return (
    <MuiGlobalStyles
      styles={{
        '.react-pdf__Page': {
          backgroundColor: 'transparent !important',
          display: 'flex',
          justifyContent: 'center',
        },
      }}
    />
  );
}
