import { useContext } from 'react';
import { DrawerContext } from '@/contexts/DrawerContext';

export function useDrawerContext() {
  const context = useContext(DrawerContext);

  if (context === undefined) {
    throw new Error('useDrawerContext must be used within a DrawerContext.Provider');
  }

  return context;
}
