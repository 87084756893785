export const queryKeys = {
  audit: (policyId: string) => ['audit', policyId],
  document: (filePath: string) => ['document', filePath],
  documentBlob: (filePath: string) => ['document', filePath, 'blob'],
  documents: (parentId: string, policyVersion?: number) => [
    'documents',
    parentId,
    policyVersion,
  ],
  payments: (policyId: string) => ['payments', policyId],
  notes: (parentId: string) => ['notes', parentId],
  noteAttachment: (filePath: string) => ['note-attachment', filePath],
  motorPolicy: (id: string, version?: number) => ['motor-policy', id, version],
  policy: (id: string) => ['policies', id],
  policies: (params?: object) => ['policies', params].filter(el => !!el),
  policyVersions: (policyId: string) => ['policy-versions', policyId],
  adminReminders: (params?: object) =>
    params ? ['admin-reminders', params] : ['admin-reminders'],
  individualReminders: (params?: object) =>
    params ? ['individual-reminders', params] : ['individual-reminders'],
  roles: () => ['roles'],
  reminderGroups: () => ['reminder-groups'],
  individuals: () => ['individuals'],
  users: (params?: object) => (params ? ['users', params] : ['users']),
  quotes: (params: object) => ['quotes', params],
  quote: (id: string) => ['quote', id],
  vulnerabilities: (policyId: string) => ['vulnerabilities', policyId],
  vehicleReg: (registration: string) => ['vehicle-reg', registration],
  coverOptions: (productName: string, productVersion: string, schemeName: string) => [
    'cover-options',
    `${productName}-${productVersion}-${schemeName}`,
  ],
  driverOptions: (productName: string, productVersion: string) => [
    'driver-options',
    `${productName}-${productVersion}`,
  ],
  employmentStatuses: (productName: string, productVersion: string) => [
    'employment-statuses',
    `${productName}-${productVersion}`,
  ],
  occupations: (productName: string, productVersion: string) => [
    'occupations',
    `${productName}-${productVersion}`,
  ],
  studentOccupations: (productName: string, productVersion: string) => [
    'student-occupations',
    `${productName}-${productVersion}`,
  ],
  employmentIndustries: (productName: string, productVersion: string) => [
    'employment-industries',
    `${productName}-${productVersion}`,
  ],
  vehicleOptions: (productName: string, productVersion: string) => [
    'vehicle-options',
    `${productName}-${productVersion}`,
  ],
  vehicleOwner: (productName: string, productVersion: string) => [
    'vehicle-owner',
    `${productName}-${productVersion}`,
  ],
  addresses: (postcode: string) => ['addresses', postcode],
  addressDetails: (postKey: string) => ['address-detail', postKey],
  vehicleMods: (productName: string, productVersion: string) => [
    'vehicle-modifications',
    `${productName}-${productVersion}`,
  ],
  vehicleMake: (vehicleType?: string, registrationYear?: number) => [
    'vehicle-make',
    `${vehicleType}-${registrationYear}`,
  ],
  vehicleModel: (
    vehicleType: string,
    make?: string,
    registrationYear?: number,
    fuelType?: string,
    transmission?: string,
  ) => [
    'vehicle-model',
    `${vehicleType}-${make}-${registrationYear}-${fuelType}-${transmission}`,
  ],
  drivingQualifications: (productName: string, productVersion: string) => [
    'driving-qualifications',
    `${productName}-${productVersion}`,
  ],
  licenseDurations: (productName: string, productVersion: string) => [
    'license-durations',
    `${productName}-${productVersion}`,
  ],
  medicalConditions: (productName: string, productVersion: string) => [
    'medical-conditions',
    `${productName}-${productVersion}`,
  ],
  noClaimsDiscountYears: (productName: string, productVersion: string) => [
    'no-claims-discount-years',
    `${productName}-${productVersion}`,
  ],
  otherVehicleUsages: (productName: string, productVersion: string) => [
    'other-vehicle-usages',
    `${productName}-${productVersion}`,
  ],
  claimTypes: (productName: string, productVersion: string) => [
    'claim-types',
    `${productName}-${productVersion}`,
  ],
  convictionTypes: (productName: string, productVersion: string) => [
    'conviction-types',
    `${productName}-${productVersion}`,
  ],
  endorsements: (productName: string, productVersion: string) => [
    'endorsements',
    `${productName}-${productVersion}`,
  ],
  order: (id: string) => ['order', id],
  storedPayments: (shopperReference: string) => ['stored-payments', shopperReference],
  debt: (policyId: string) => ['debt', policyId],
  unsettledBalance: (policyId: string) => ['debt', policyId, 'unsettled-balance'],
  motorQuote: (id: string) => ['motor-quote', id],
};
