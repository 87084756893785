import { YesNo } from '@/models/YesNo';

export const booleanAnswer = (value: boolean | null | undefined) =>
  value ? YesNo.Yes : YesNo.No;

export const validationMessages = {
  required: 'Required',
  maxLength: (len: number) => `Maximum ${len} characters allowed`,
  minLength: (len: number) => `Minimum ${len} characters required`,
  minValue: (val: number) => `Minimum value is ${val}`,
  maxValue: (val: number) => `Maximum value is ${val}`,
  email: 'This is not a valid email',
};

export function genderByTitle(title: string | null | undefined) {
  switch (title?.toLowerCase()) {
    case 'mr':
      return 'Male';
    case 'mrs':
    case 'miss':
    case 'ms':
      return 'Female';
    default:
      return 'Other';
  }
}

export function convertPascalCaseToText(value: string): string {
  return value
    .replace(/([A-Z])/g, ' $1')
    .trim()
    .toLowerCase()
    .replace(/^(.)/, firstLetter => firstLetter.toUpperCase());
}
