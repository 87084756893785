/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ReindexDataRequestDto } from '../models/ReindexDataRequestDto';
import type { ReindexDataResponseDto } from '../models/ReindexDataResponseDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class IndexService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns ReindexDataResponseDto Success
   * @throws ApiError
   */
  public postApiV1IndexReindexData({
    requestBody,
  }: {
    requestBody?: ReindexDataRequestDto,
  }): CancelablePromise<ReindexDataResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/index/reindex-data',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
      },
    });
  }
}
