/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddDebtRequestDto } from '../models/AddDebtRequestDto';
import type { GetDebtResponseDto } from '../models/GetDebtResponseDto';
import type { GetPaymentInformationResponseDto } from '../models/GetPaymentInformationResponseDto';
import type { PlaceOrderResponseDto } from '../models/PlaceOrderResponseDto';
import type { SettleDebtRequestDto } from '../models/SettleDebtRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PaymentsService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns GetPaymentInformationResponseDto Success
   * @throws ApiError
   */
  public getApiV1Payments({
    policyId,
  }: {
    policyId: string,
  }): CancelablePromise<GetPaymentInformationResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/payments/{policyId}',
      path: {
        'policyId': policyId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @returns GetDebtResponseDto Success
   * @throws ApiError
   */
  public getApiV1PaymentsDebt({
    policyId,
  }: {
    policyId: string,
  }): CancelablePromise<GetDebtResponseDto> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/payments/debt/{policyId}',
      path: {
        'policyId': policyId,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @returns PlaceOrderResponseDto Success
   * @throws ApiError
   */
  public postApiV1PaymentsDebt({
    policyId,
    requestBody,
  }: {
    policyId: string,
    requestBody?: AddDebtRequestDto,
  }): CancelablePromise<PlaceOrderResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/payments/debt/{policyId}',
      path: {
        'policyId': policyId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @returns PlaceOrderResponseDto Success
   * @throws ApiError
   */
  public postApiV1PaymentsSettleDebt({
    policyId,
    requestBody,
  }: {
    policyId: string,
    requestBody?: SettleDebtRequestDto,
  }): CancelablePromise<PlaceOrderResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/payments/{policyId}/settle-debt',
      path: {
        'policyId': policyId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        403: `Forbidden`,
        404: `Not Found`,
      },
    });
  }
}
