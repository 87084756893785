import { PropsWithChildren, createContext, useMemo } from 'react';
import { SupportAPI, OpenAPI as SupportAPIConfig } from '@/api/support';
import { NoteAPI, OpenAPI as NoteAPIConfig } from '@/api/note';
import {
  VulnerabilitiesAPI,
  OpenAPI as VulnerabilitiesAPIConfig,
} from '@/api/vulnerabilities';
import { RetailAPI as RetailAPIV1, OpenAPI as RetailAPIV1Config } from '@/api/retail/v1';
import { RetailAPI as RetailAPIV2, OpenAPI as RetailAPIV2Config } from '@/api/retail/v2';
import { MotorpolicyAPI, OpenAPI as MotorpolicyAPIConfig } from '@/api/motorpolicy';
import { MotorquoteAPI, OpenAPI as MotorquoteAPIConfig } from '@/api/motorquote';

export type ApiClient = {
  support: SupportAPI;
  note: NoteAPI;
  vulnerabilities: VulnerabilitiesAPI;
  motorpolicy: MotorpolicyAPI;
  motorquote: MotorquoteAPI;
  retailV1: RetailAPIV1;
  retailV2: RetailAPIV2;
};

export const ApiClientContext = createContext<ApiClient | undefined>(undefined);

const supportApi = new SupportAPI({
  ...SupportAPIConfig,
  BASE: import.meta.env.VITE_APP_SUPPORT_API_URL,
});

const noteApi = new NoteAPI({
  ...NoteAPIConfig,
  BASE: import.meta.env.VITE_APP_NOTE_API_URL,
});

const vulnerabilitiesApi = new VulnerabilitiesAPI({
  ...VulnerabilitiesAPIConfig,
  BASE: import.meta.env.VITE_APP_VULNERABILITIES_API_URL,
});

const motorpolicyApi = new MotorpolicyAPI({
  ...MotorpolicyAPIConfig,
  BASE: import.meta.env.VITE_APP_MOTORPOLICY_API_URL,
});

const motorquoteApi = new MotorquoteAPI({
  ...MotorquoteAPIConfig,
  BASE: import.meta.env.VITE_APP_MOTORQUOTE_API_URL,
});

const retailApiV1 = new RetailAPIV1({
  ...RetailAPIV1Config,
  BASE: import.meta.env.VITE_APP_RETAIL_API_URL,
});

const retailApiV2 = new RetailAPIV2({
  ...RetailAPIV2Config,
  BASE: import.meta.env.VITE_APP_RETAIL_API_URL,
});

export function ApiClientProvider({ children }: PropsWithChildren) {
  const client = useMemo(
    () => ({
      support: supportApi,
      note: noteApi,
      vulnerabilities: vulnerabilitiesApi,
      motorpolicy: motorpolicyApi,
      motorquote: motorquoteApi,
      retailV1: retailApiV1,
      retailV2: retailApiV2,
    }),
    [],
  );

  return <ApiClientContext.Provider value={client}>{children}</ApiClientContext.Provider>;
}
