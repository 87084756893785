import { useFormContext, useWatch } from 'react-hook-form';

export function useWatchFormField<T>(fieldName: string) {
  const { control } = useFormContext();

  return useWatch({
    control,
    name: fieldName,
  }) as T;
}
