export function DayinsureLogo() {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M12.4212 24C5.24114 24 0 19.6013 0 11.9767C0 4.35209 5.66872 0 12.5609 0C19.4532 0 24.2244 4.53837 24.2244 11.2739C24.2244 12.3958 24.1312 13.7548 24.1312 13.7548H7.55689C7.88711 17.0781 10.4357 18.5726 13.5093 18.5726C16.5828 18.5726 19.178 17.2644 20.4989 16.3754L23.475 20.2575C22.7172 20.8206 19.6479 24 12.4255 24H12.4212ZM12.4212 4.86435C9.30534 4.86435 7.69659 7.29864 7.60346 9.49586H17.1924C17.1459 7.39178 15.5879 4.86435 12.4212 4.86435Z"
        fill="white"
      />
    </svg>
  );
}
