import { Color } from '@mui/material';

// Color generated from: https://m2.material.io/inline-tools/color/
// Based on #555555
// grey[700] - main color
// grey[600] - opacity 0.9
// grey[500] - opacity ~0.65
// grey[400] - opacity ~0.45
// grey[300] - opacity ~0.22
// grey[200] - opacity ~0.13
// grey[100] - opacity ~0.06
export const grey: Color = {
  50: '#f8f8f8',
  100: '#f0f0f0',
  200: '#e6e6e6',
  300: '#d5d5d5',
  400: '#b0b0b0',
  500: '#909090',
  600: '#686868',
  700: '#555555',
  800: '#373737',
  900: '#171717',
  A100: '#f0f0f0',
  A200: '#e6e6e6',
  A400: '#b0b0b0',
  A700: '#555555',
};
