/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum Dayinsure_Common_Contracts_MotorPolicy_Enums_PolicyTransactionType {
  NEW_BUSINESS = 'NewBusiness',
  MID_TERM_ADJUSTMENT = 'MidTermAdjustment',
  RENEWAL = 'Renewal',
  CANCELLATION = 'Cancellation',
  VOIDANCE = 'Voidance',
  LOCK_CHANGE = 'LockChange',
  POLICYHOLDER_ACCOUNT_UPDATE = 'PolicyholderAccountUpdate',
  DEBT = 'Debt',
  REVERSAL = 'Reversal',
}
