import { PropsWithChildren, useEffect, useLayoutEffect } from 'react';
import { useAuth } from 'react-oidc-context';
import { useApiClient } from '@/hooks/contexts/useApiClient';

export function AuthEventsHandler({ children }: PropsWithChildren) {
  const auth = useAuth();
  const apiClients = useApiClient();
  const accessToken = auth.user?.access_token;

  useLayoutEffect(() => {
    Object.values(apiClients).forEach(apiClient => {
      apiClient.request.config.TOKEN = accessToken;
    });
  }, [accessToken, apiClients]);

  // If the token can't be automatically refreshed, and expires
  // attempt a refresh and then restart the silent renew.
  useEffect(
    () =>
      auth.events.addAccessTokenExpired(() => {
        void auth.signinSilent().then(() => {
          auth.stopSilentRenew();
          auth.startSilentRenew();
        });
      }),
    [auth],
  );

  return children;
}
