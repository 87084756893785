import { Box, Popover } from '@mui/material';
import { FormDateRangePicker } from '@/components/ui/forms/FormDateRangePicker';
import { getPolicyMaxDate, getPolicyMinDate } from '@/helpers/policyHelpers';

type DateRangePopoverProps = {
  id: string | undefined;
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
};

export function DateRangePopover({ id, open, anchorEl, onClose }: DateRangePopoverProps) {
  return (
    <Popover
      id={id}
      open={open}
      anchorEl={anchorEl}
      onClose={onClose}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'left',
      }}
    >
      <Box p={4}>
        <FormDateRangePicker minDate={getPolicyMinDate()} maxDate={getPolicyMaxDate()} />
      </Box>
    </Popover>
  );
}
