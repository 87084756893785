import { createRoot } from 'react-dom/client';
import { StrictMode } from 'react';
import { pdfjs } from 'react-pdf';
import { App } from './App.tsx';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'react-pdf/dist/Page/AnnotationLayer.css';
import 'react-pdf/dist/Page/TextLayer.css';
import '@adyen/adyen-web/dist/adyen.css';

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.js',
  import.meta.url,
).toString();

const deferRender = async () => {
  if (
    process.env.NODE_ENV === 'development' &&
    import.meta.env.VITE_APP_ENABLE_MSW === 'true'
  ) {
    const { worker } = await import('./mocks/browser');
    return worker.start();
  }
};

deferRender()
  .then(() =>
    createRoot(document.getElementById('root')!).render(
      <StrictMode>
        <App />
      </StrictMode>,
    ),
  )
  .catch(err => {
    throw err;
  });
