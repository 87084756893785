import { Box, styled } from '@mui/material';

export const StyledDrawerContent = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  width: 'inherit',
  padding: theme.spacing(3),
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(3),
}));

StyledDrawerContent.defaultProps = {
  ...StyledDrawerContent.defaultProps,
  component: 'section',
};
