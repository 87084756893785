import { useQuery } from '@tanstack/react-query';
import { useApiClient } from '@/hooks/contexts/useApiClient';
import { queryKeys } from '@/helpers/queryKeys';
import { GetRemindersResponseDto, ReminderFilterType } from '@/api/note';
import { ReminderDetail } from '@/models/ReminderDetail';

type RemindersParams = { filterType: ReminderFilterType };

export function useIndividualRemindersQuery(filters: RemindersParams) {
  const api = useApiClient();

  const fetchIndividualReminders = () => api.note.notes.getApiV1NotesReminders(filters);

  const query = useQuery<GetRemindersResponseDto>({
    queryKey: queryKeys.individualReminders(filters),
    queryFn: fetchIndividualReminders,
  });

  const reminders = (query.data?.remindersDetails ?? []) as ReminderDetail[];

  return {
    ...query,
    reminders,
  };
}
