/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddCustomerVulnerabilitySupportRequestDto } from '../models/AddCustomerVulnerabilitySupportRequestDto';
import type { AddCustomerVulnerabilitySupportResponseDto } from '../models/AddCustomerVulnerabilitySupportResponseDto';
import type { UpdateCustomerVulnerabilitySupportRequestDto } from '../models/UpdateCustomerVulnerabilitySupportRequestDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class CustomerVulnerabilityService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns AddCustomerVulnerabilitySupportResponseDto Success
   * @throws ApiError
   */
  public postApiV1CustomerVulnerabilities({
    requestBody,
  }: {
    requestBody?: AddCustomerVulnerabilitySupportRequestDto,
  }): CancelablePromise<AddCustomerVulnerabilitySupportResponseDto> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/customer-vulnerabilities',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns AddCustomerVulnerabilitySupportResponseDto Success
   * @throws ApiError
   */
  public patchApiV1CustomerVulnerabilities({
    id,
    requestBody,
  }: {
    id: string,
    requestBody?: UpdateCustomerVulnerabilitySupportRequestDto,
  }): CancelablePromise<AddCustomerVulnerabilitySupportResponseDto> {
    return this.httpRequest.request({
      method: 'PATCH',
      url: '/api/v1/customer-vulnerabilities/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        422: `Client Error`,
      },
    });
  }
  /**
   * @returns void
   * @throws ApiError
   */
  public deleteApiV1CustomerVulnerabilities({
    id,
    noteId,
  }: {
    id: string,
    noteId?: string,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v1/customer-vulnerabilities/{id}',
      path: {
        'id': id,
      },
      query: {
        'noteId': noteId,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }
}
