/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AssetCategory } from '../models/AssetCategory';
import type { ConfirmAccountEmailRequest } from '../models/ConfirmAccountEmailRequest';
import type { ConfirmAccountResponse } from '../models/ConfirmAccountResponse';
import type { ConfirmPasswordResetRequest } from '../models/ConfirmPasswordResetRequest';
import type { IAPIResult } from '../models/IAPIResult';
import type { InsuredAssetBaseIEnumerableIAPIResult } from '../models/InsuredAssetBaseIEnumerableIAPIResult';
import type { PasswordChangeRequest } from '../models/PasswordChangeRequest';
import type { PersonRequest } from '../models/PersonRequest';
import type { PersonResponse } from '../models/PersonResponse';
import type { PersonResponseV2 } from '../models/PersonResponseV2';
import type { PolicySummaryListIAPIResult } from '../models/PolicySummaryListIAPIResult';
import type { SendPasswordResetRequest } from '../models/SendPasswordResetRequest';
import type { SendVerificationEmailRequest } from '../models/SendVerificationEmailRequest';
import type { UpdatePersonRequestV2 } from '../models/UpdatePersonRequestV2';
import type { VerifyEmailAddressRequest } from '../models/VerifyEmailAddressRequest';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class PersonService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Gets a person given an ID
   * @returns PersonResponseV2 Success
   * @throws ApiError
   */
  public getApiV2Person({
    id,
  }: {
    /**
     * An ID representing a person
     */
    id: string,
  }): CancelablePromise<PersonResponseV2> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/person/{id}',
      path: {
        'id': id,
      },
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Updates an existing person given the id.  Properties given will be added or updated
   * @returns void
   * @throws ApiError
   */
  public putApiV2Person({
    id,
    requestBody,
  }: {
    /**
     * An ID representing a person
     */
    id: string,
    /**
     * Request containing a persons details.
     */
    requestBody?: UpdatePersonRequestV2,
  }): CancelablePromise<void> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/person/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Deletes an existing person given the id.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public deleteApiV2Person({
    id,
  }: {
    /**
     * An ID representing a person
     */
    id: string,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'DELETE',
      url: '/api/v2/person/{id}',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Creates a new person object given properties of that person
   * @returns PersonResponse Success
   * @throws ApiError
   */
  public postApiV2Person({
    requestBody,
  }: {
    /**
     * Request containing a persons details.
     */
    requestBody?: PersonRequest,
  }): CancelablePromise<PersonResponse> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        422: `Client Error`,
      },
    });
  }
  /**
   * Sends a password reset email.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV2PersonSendPasswordReset({
    email,
    requestBody,
  }: {
    /**
     * The email address to send the password reset email to.
     */
    email: string,
    /**
     * Model containing the return Url.
     */
    requestBody?: SendPasswordResetRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{email}/send-password-reset',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
      },
    });
  }
  /**
   * Uses the password token to confirm request and updates the email
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV2PersonConfirmPasswordReset({
    personId,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
    /**
     * Model containing password and password token
     */
    requestBody?: ConfirmPasswordResetRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{personId}/confirm-password-reset',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Sends a new email verification email to the given email address if an unverified person with the email address exists
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV2PersonSendEmailVerification({
    email,
    requestBody,
  }: {
    /**
     * The email address to send the verification to
     */
    email: string,
    /**
     * Model containing the return Url
     */
    requestBody?: SendVerificationEmailRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{email}/send-email-verification',
      path: {
        'email': email,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * Verifies the email address of the user by security id.
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV2PersonVerifyEmailAddress({
    personId,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
    /**
     * Model containing the email details to be verified
     */
    requestBody?: VerifyEmailAddressRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{personId}/verify-email-address',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        404: `Not Found`,
      },
    });
  }
  /**
   * Sends a new email verification email to the given personId if an unverified person with the email address exists
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public postApiV2PersonConfirmAccountEmail({
    personId,
    requestBody,
  }: {
    /**
     * The email address to send the verification to
     */
    personId: string,
    /**
     * request containing the return Url
     */
    requestBody?: ConfirmAccountEmailRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v2/person/{personId}/confirm-account-email',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
    });
  }
  /**
   * Sends a new email to check if a confirm account exists
   * @returns ConfirmAccountResponse Success
   * @throws ApiError
   */
  public getApiV2PersonConfirmAccountExists({
    email,
  }: {
    email: string,
  }): CancelablePromise<ConfirmAccountResponse> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/person/{email}/confirm-account-exists',
      path: {
        'email': email,
      },
      errors: {
        400: `Bad Request`,
      },
    });
  }
  /**
   * Get policy summaries by the user id.
   * @returns PolicySummaryListIAPIResult Success
   * @throws ApiError
   */
  public getApiV2PersonPolicySummary({
    personId,
  }: {
    /**
     * An ID representing a person Id
     */
    personId: string,
  }): CancelablePromise<PolicySummaryListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/person/{personId}/policy-summary',
      path: {
        'personId': personId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * @returns InsuredAssetBaseIEnumerableIAPIResult Success
   * @throws ApiError
   */
  public getApiV2PersonInsuredAsset({
    personId,
    category,
  }: {
    personId: string,
    category?: AssetCategory,
  }): CancelablePromise<InsuredAssetBaseIEnumerableIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/person/{personId}/insured-asset',
      path: {
        'personId': personId,
      },
      query: {
        'category': category,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Updates the current password with the new password provided
   * @returns IAPIResult Success
   * @throws ApiError
   */
  public putApiV2PersonChangePassword({
    personId,
    requestBody,
  }: {
    /**
     * The security id representing the person identification
     */
    personId: string,
    /**
     * Model containing the current and new password
     */
    requestBody?: PasswordChangeRequest,
  }): CancelablePromise<IAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v2/person/{personId}/change-password',
      path: {
        'personId': personId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
}
