/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AcceptanceCriteriaGroupIEnumerableIAPIResult } from '../models/AcceptanceCriteriaGroupIEnumerableIAPIResult';
import type { CreateQuoteRequest } from '../models/CreateQuoteRequest';
import type { FraudCheckRequest } from '../models/FraudCheckRequest';
import type { PaymentMethodResponseIAPIResult } from '../models/PaymentMethodResponseIAPIResult';
import type { PolicyPollingResponseIAPIResult } from '../models/PolicyPollingResponseIAPIResult';
import type { PurchaseQuoteRequest } from '../models/PurchaseQuoteRequest';
import type { PurchaseQuoteResponseIAPIResult } from '../models/PurchaseQuoteResponseIAPIResult';
import type { QuoteAuthDetailsIAPIResult } from '../models/QuoteAuthDetailsIAPIResult';
import type { QuoteIAPIResult } from '../models/QuoteIAPIResult';
import type { RefusalCheckIAPIResult } from '../models/RefusalCheckIAPIResult';
import type { ResumePurchaseQuoteRequest } from '../models/ResumePurchaseQuoteRequest';
import type { UpdateQuoteRequest } from '../models/UpdateQuoteRequest';
import type { WebDocumentListIAPIResult } from '../models/WebDocumentListIAPIResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class QuoteService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * Creates a new quote with the given person(s) and asset(s).
   * @returns QuoteIAPIResult Success
   * @throws ApiError
   */
  public postApiV1Quote({
    requestBody,
  }: {
    /**
     * An object representing the quote to be created.
     */
    requestBody?: CreateQuoteRequest,
  }): CancelablePromise<QuoteIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/quote',
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
      },
    });
  }
  /**
   * Updates an existing quote given a different start date, a different duration and/or different add ons.  This approach is quicker than re-quoting if only these factors change as it bypasses many underwriting checks on the person and asset.
   * @returns QuoteIAPIResult Success
   * @throws ApiError
   */
  public putApiV1Quote({
    id,
    requestBody,
  }: {
    /**
     * An ID representing a quote
     */
    id: string,
    /**
     * An Object representing the properties of a quote you want to update
     */
    requestBody?: UpdateQuoteRequest,
  }): CancelablePromise<QuoteIAPIResult> {
    return this.httpRequest.request({
      method: 'PUT',
      url: '/api/v1/quote/{id}',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Retrieves quote details for a given quote ID
   * @returns QuoteIAPIResult Success
   * @throws ApiError
   */
  public getApiV1Quote({
    quoteId,
  }: {
    /**
     * A unique ID for a quote
     */
    quoteId: string,
  }): CancelablePromise<QuoteIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{quoteId}',
      path: {
        'quoteId': quoteId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Makes a fraud check for a given quote ID
   * @returns RefusalCheckIAPIResult Success
   * @throws ApiError
   */
  public postApiV1QuoteFraudCheck({
    id,
    requestBody,
  }: {
    /**
     * An ID representing a quote
     */
    id: string,
    /**
     * An Object containing a BlackBoxId and ClientIPAddress for the fraud check.
     */
    requestBody?: FraudCheckRequest,
  }): CancelablePromise<RefusalCheckIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/quote/{id}/fraud-check',
      path: {
        'id': id,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets the acceptance criteria for a given quote.  Criteria that has been already confirmed by lookup services (e.g. Vehicle lookup, MyLicence) will not be returned
   * @returns AcceptanceCriteriaGroupIEnumerableIAPIResult Success
   * @throws ApiError
   */
  public getApiV1QuoteAcceptanceCriteria({
    quoteId,
  }: {
    quoteId: string,
  }): CancelablePromise<AcceptanceCriteriaGroupIEnumerableIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{quoteId}/acceptance-criteria',
      path: {
        'quoteId': quoteId,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Gets the supported payment methods for a given quote
   * @returns PaymentMethodResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV1QuotePaymentMethod({
    quoteId,
    channel,
    countryCode,
  }: {
    quoteId: string,
    channel?: string,
    countryCode?: string,
  }): CancelablePromise<PaymentMethodResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{quoteId}/payment-method',
      path: {
        'quoteId': quoteId,
      },
      query: {
        'channel': channel,
        'countryCode': countryCode,
      },
    });
  }
  /**
   * Process payment for a quote. Completing this will result in a new policy. Failing payment will allow the payment to be retried.
   * This method is still under development and is subject to change.
   * @returns PurchaseQuoteResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1QuotePurchase({
    quoteId,
    requestBody,
  }: {
    /**
     * REQUIRED
     */
    quoteId: string,
    /**
     * REQUIRED - The payment method, required fields for the payment method in Adyen encrypted format are all required
     */
    requestBody?: PurchaseQuoteRequest,
  }): CancelablePromise<PurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/quote/{quoteId}/purchase',
      path: {
        'quoteId': quoteId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        503: `Server Error`,
      },
    });
  }
  /**
   * Process payment for a quote. Completing this will result in a new policy. Failing payment will allow the payment to be retried.
   * This method is still under development and is subject to change.
   * @returns PurchaseQuoteResponseIAPIResult Success
   * @throws ApiError
   */
  public postApiV1QuoteResumePurchase({
    quoteId,
    requestBody,
  }: {
    /**
     * REQUIRED
     */
    quoteId: string,
    /**
     * REQUIRED - The payment method, required fields for the payment method in Adyen encrypted format are all required
     */
    requestBody?: ResumePurchaseQuoteRequest,
  }): CancelablePromise<PurchaseQuoteResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'POST',
      url: '/api/v1/quote/{quoteId}/resume-purchase',
      path: {
        'quoteId': quoteId,
      },
      body: requestBody,
      mediaType: 'application/json-patch+json',
      errors: {
        400: `Bad Request`,
        401: `Unauthorized`,
        503: `Server Error`,
      },
    });
  }
  /**
   * Get a policy by the quote id provided.
   * @returns PolicyPollingResponseIAPIResult Success
   * @throws ApiError
   */
  public getApiV1QuotePolicy({
    id,
  }: {
    /**
     * REQUIRED
     */
    id: string,
  }): CancelablePromise<PolicyPollingResponseIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{id}/policy',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Retrieves quote and provide status of either quote or policy
   * @returns QuoteAuthDetailsIAPIResult Success
   * @throws ApiError
   */
  public getApiV1QuoteAuthDetails({
    id,
  }: {
    id: string,
  }): CancelablePromise<QuoteAuthDetailsIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{id}/auth-details',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
  /**
   * Get a policy by the quote id provided.
   * @returns WebDocumentListIAPIResult Success
   * @throws ApiError
   */
  public getApiV1QuoteDocuments({
    id,
  }: {
    /**
     * REQUIRED
     */
    id: string,
  }): CancelablePromise<WebDocumentListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quote/{id}/documents',
      path: {
        'id': id,
      },
      errors: {
        401: `Unauthorized`,
        404: `Not Found`,
      },
    });
  }
}
