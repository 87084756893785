import { DateTimePicker, DateTimePickerProps } from '@mui/x-date-pickers';
import { useController, useFormContext } from 'react-hook-form';
import { getErrorProps } from '@/helpers/formHelper';

type FormDateTimePickerProps = {
  name: string;
  testId?: string;
} & DateTimePickerProps<Date, boolean>;

const getTestIdProps = (testId?: string) =>
  testId ? { 'data-testid': testId } : undefined;

export function FormDateTimePicker({ name, testId, ...props }: FormDateTimePickerProps) {
  const { control } = useFormContext();

  const {
    field,
    fieldState: { error },
  } = useController({
    name,
    control,
  });

  return (
    <DateTimePicker
      {...field}
      {...props}
      slotProps={{
        ...props.slotProps,
        textField: {
          ...props.slotProps?.textField,
          ...getErrorProps(error),
          inputProps: getTestIdProps(testId),
        },
      }}
    />
  );
}
