/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { AddressDetailsIAPIResult } from '../models/AddressDetailsIAPIResult';
import type { AddressV1ListIAPIResult } from '../models/AddressV1ListIAPIResult';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class AddressService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * GetAddressByPostCode - Returns a list of addresses for a given postcode each with an accompanying postkey to uniquely identify the address and a short summary
   * @returns AddressV1ListIAPIResult Success
   * @throws ApiError
   */
  public getApiV2Address({
    postcode,
    houseNumber,
  }: {
    /**
     * REQUIRED - A valid UK Postcode e.g. A9 9AA
     */
    postcode: string,
    /**
     * OPTIONAL - The house number of the desired address used to restrict matching results
     */
    houseNumber?: string,
  }): CancelablePromise<AddressV1ListIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/address/{postcode}',
      path: {
        'postcode': postcode,
      },
      query: {
        'houseNumber': houseNumber,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
  /**
   * GetAddressByPostKey - Gets a detailed address given a postkey (returned from an address postcode lookup)
   * @returns AddressDetailsIAPIResult Success
   * @throws ApiError
   */
  public getApiV2AddressDetail({
    postkey,
  }: {
    /**
     * REQUIRED - A unique string identifier for an address (returned from an address summary)
     */
    postkey: string,
  }): CancelablePromise<AddressDetailsIAPIResult> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v2/address/{postkey}/detail',
      path: {
        'postkey': postkey,
      },
      errors: {
        400: `Bad Request`,
        404: `Not Found`,
      },
    });
  }
}
