import CircleIcon from '@mui/icons-material/Circle';
import { useTheme, Chip } from '@mui/material';
import { StateChipType } from './StateChipType';

type StateChipProps = {
  label: string;
  type: StateChipType;
  testId?: string;
};

function StateIcon({ type }: { type: StateChipType }) {
  const theme = useTheme();

  const getColor = (): string => {
    switch (type) {
      case StateChipType.Success:
        return theme.palette.success.main;
      case StateChipType.Neutral:
        return theme.palette.grey[400];
      case StateChipType.Warning:
        return theme.palette.warning.main;
      case StateChipType.Error:
        return theme.palette.error.main;
      case StateChipType.Info:
        return theme.palette.info.main;
    }
  };

  return (
    <CircleIcon
      sx={{
        color: getColor(),
        fontSize: '8px',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(-0.5),
      }}
    />
  );
}

export function StateChip({ label, type, testId }: StateChipProps) {
  return (
    <Chip
      data-testid={testId}
      label={label}
      variant="outlined"
      size="small"
      icon={<StateIcon type={type} />}
    />
  );
}
