/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
export enum ReminderStatus {
  UPCOMING = 'Upcoming',
  DUE = 'Due',
  OVER_DUE = 'OverDue',
  COMPLETE = 'Complete',
}
