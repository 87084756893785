/* generated using openapi-typescript-codegen -- do not edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FilterType } from '../models/FilterType';
import type { QuoteDto } from '../models/QuoteDto';
import type { CancelablePromise } from '../core/CancelablePromise';
import type { BaseHttpRequest } from '../core/BaseHttpRequest';
export class QuotesService {
  constructor(public readonly httpRequest: BaseHttpRequest) {}
  /**
   * @returns QuoteDto Success
   * @throws ApiError
   */
  public getApiV1Quotes({
    dateFrom,
    dateTo,
    filterType,
    filterValue,
    skip,
    take,
  }: {
    dateFrom?: string,
    dateTo?: string,
    filterType?: FilterType,
    filterValue?: string,
    skip?: number,
    take?: number,
  }): CancelablePromise<Array<QuoteDto>> {
    return this.httpRequest.request({
      method: 'GET',
      url: '/api/v1/quotes',
      query: {
        'DateFrom': dateFrom,
        'DateTo': dateTo,
        'FilterType': filterType,
        'FilterValue': filterValue,
        'Skip': skip,
        'Take': take,
      },
      errors: {
        400: `Bad Request`,
        422: `Client Error`,
      },
    });
  }
}
